import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import {BottomErrMsg} from "component/basic/popup";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';

const New = (props) => {
    const navigate = useNavigate();

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                func={() => {navigate(-1)}}
                centerTitle={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="loginBox">
                    <div className="loginTitleBox">
                        <div className="loginTitle">아직 가입하신 계정이 없네요.</div>
                        <div className="loginContents">이어서 회원가입을 진행할까요?</div>
                    </div>
                    <div className="snsLoginBox">
                        <button onClick={() => {
                            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code`
                        }}><img src="/assets/images/basic/kakao_login.svg"/></button>
                        <button><img src="/assets/images/basic/naver_login.svg"/></button>
                        <button><img src="/assets/images/basic/google_login.svg"/></button>
                        <button><img src="/assets/images/basic/apple_login.svg"/></button>
                    </div>
                    <div className="phoneLogin">
                        <a href="/join">휴대폰 번호로 계속하기 ></a>
                    </div>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default New;