import React, { useState } from "react";
import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { useNavigate } from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, PageBody, PageSubHeader } from "component/app/items";
import { Header } from "component/elements/header";
import {comFormat} from "../../js/function";
import getDiscountPrice from "../../component/product/getDiscountPrice";

const Main = (props) => {
    const navigate = useNavigate();

    const [update, setUpdate] =  useState(1);
    const [category, setCategory] =  useState('');
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const productApi = useGet({url: `/product/shop?categoryId=${category}&update=${update}`, loginType:"login"});
    const categoryApi = useGet({url: `/category`});
    const bannerApi = useGet({url: `/banner`});
    const cartCountApi = useGet({url: `/cart/count`, loginType:"login"});

    function handleLike(registrationNumber, isLike) {
        if (!localStorage.getItem("token")) {
            navigate("/login");
        }

        let formData = `registrationNumber=${encodeURIComponent(registrationNumber)}`;

        fatchSet.FatchApi({
            type: isLike === '1' ? "DELETE" : "POST",
            formDataItem: formData,
            contentType: "application/x-www-form-urlencoded",
            loginType:"login",
            url: isLike === '1' ? `/product/product-like-cancel` : `/product/product-like`,
            success: (data) => {
                setUpdate(update + 1)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                addClass="pageMainBg"
                pageBack={navigator.userAgent.match("pharmpickApp") != null}
                func={() => {navigate(-1)}}
                cartCount={cartCountApi}
                cartBtn={true}
                profileBtn={true}
                headTitle="팜픽 마켓"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader image={bannerApi ? bannerApi[0] : ''}/>
                <PageBody>
                    <div className="categoryBox">
                        <div className={`categoryItem ${category === '' ? 'active' : ''}`} onClick={() => setCategory('')}>전체</div>
                        {categoryApi?.length > 0 && categoryApi?.map((category_) => (
                            <div className={`categoryItem ${category === category_?.id ? 'active' : ''}`} onClick={() => setCategory(category_?.id)}>{category_?.category}</div>
                        ))}
                    </div>
                    <div className="productContentsBox" style={{ marginBottom: 0 }}>
                        <div className="productBox">
                            {productApi?.length > 0 && productApi?.map((product) => (
                                <div className="productItem" onClick={(e) => { if (e.target.className !== 'likeBox' && e.target.className !== 'likeIcon') { navigate(`/product/detail/${product?.registrationNumber}`) }} }>
                                    <div className="productImage">
                                        {product?.isRegular === 1 && (<span className="label">정기배송</span>)}
                                        <img src={product?.imageUrl}/>
                                        <div className="likeBox" onClick={() => handleLike(product?.registrationNumber, product?.isLike)}>
                                            <img className="likeIcon" src={product?.isLike === "1" ? '/assets/images/icon/heartOn.svg' : '/assets/images/icon/heartOff.svg'}/>
                                        </div>
                                    </div>
                                    <div className="productContents">
                                        <div className="productName">{product?.name}</div>
                                        <div className="productPrice">
                                            <span>{product?.discountPercent ? (product?.discountPercent + '%') : ''}</span>
                                            <span>{product?.discountPercent ? comFormat(getDiscountPrice(product?.discountPercent, product?.discountCut, product.price)) : comFormat(product?.price)}원</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="footer">
                            <div className="link">
                                <a href="">개인정보처리방침</a>
                                <a href="">이용약관</a>
                            </div>
                            <div className="contents">
                                <div>(주)고로켓컴퍼니</div>
                                <div>충청남도 천안시 서북구 불당14로 48, 충남 창업마루 나비 5층(불당동)</div>
                                <div>대표자 : 유병욱</div>
                                <div>개인정보보호책임자 : 유병욱</div>
                                <div>사업자등록번호 : 124-88-03179</div>
                                <div>통신판매업신고 : 제 2023-충남천안-2675 호</div>
                                <div>건강기능식품 영업신고 : 제 2024-0073124 호</div>
                                <div>이메일 : support@gorocket.kr</div>
                            </div>
                            <div className="hr"/>
                            <div className="contents">
                                <div>고객센터 : 1668-5616</div>
                                <div>평일 AM 10:00 ~ PM 05:00 (주말/공휴일 휴무)</div>
                                <div>점심 AM 11:30 ~ PM 01:30</div>
                            </div>
                            <div className="contents">
                                <div>Ⓒ 2024. (주)고로켓컴퍼니. All right reserved.</div>
                            </div>
                        </div>
                    </div>
                </PageBody>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Main;