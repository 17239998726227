import React, {useEffect, useCallback, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {BottomErrMsg} from "component/basic/popup";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import * as fatchSet from "../../api/api";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import {CustomSelect, InputItemBox} from "../../component/basic/formItems";
import moment from "moment/moment";
import useGet from "../../api/useGet";

const Link = (props) => {
    const navigate = useNavigate();

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    function handleSubmit(){
        let payload = {
            "userId": JSON.parse(localStorage.getItem("existAccount"))?.userId,
            "way": "phone",
            "email": JSON.parse(localStorage.getItem("existAccount"))?.email,
            "di": "",
        };

        fatchSet.FatchApi({
            type:"PUT",
            formDataItem: JSON.stringify(payload),
            loginType: "login",
            url: `/auth/link-account`,
            success: (data) => {
                var result = JSON.parse(data);
                if (result?.accessToken) {
                    localStorage.setItem("token",result.accessToken);
                    localStorage.setItem("refresh_token",result.refreshToken);

                    navigate('/')
                }
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                func={() => {navigate(-1)}}
                centerTitle={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="loginBox">
                    <div className="loginTitleBox">
                        <div className="loginTitle">이미 가입하신 계정이 있네요!</div>
                        <div className="loginContents">이 계정과 연동할까요?</div>
                    </div>
                    <div className="linkBox">
                        <div className="checkbox">
                            <img src="/assets/images/login/linkChk.svg"/>
                        </div>
                        <div className="linkAccount">
                            {JSON.parse(localStorage.getItem("existAccount"))?.email && <div><img src="/assets/images/login/phone.svg"/>{JSON.parse(localStorage.getItem("existAccount"))?.email}
                            </div>}
                            {JSON.parse(localStorage.getItem("existAccount"))?.apple && <div><img src="/assets/images/login/apple.svg"/>{JSON.parse(localStorage.getItem("existAccount"))?.apple}
                            </div>}
                            {JSON.parse(localStorage.getItem("existAccount"))?.google && <div><img src="/assets/images/login/google.svg"/>{JSON.parse(localStorage.getItem("existAccount"))?.google}
                            </div>}
                            {JSON.parse(localStorage.getItem("existAccount"))?.kakao && <div><img src="/assets/images/login/kakao.svg"/>{JSON.parse(localStorage.getItem("existAccount"))?.kakao}</div>}
                            {JSON.parse(localStorage.getItem("existAccount"))?.naver && <div><img src="/assets/images/login/naver.svg"/>{JSON.parse(localStorage.getItem("existAccount"))?.naver}
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="pageBtn_box indexUp fixButtonBox">
                    <button className="fixButton full" onClick={() => handleSubmit()}>확인</button>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Link;