import React, {useCallback, useEffect, useRef, useState} from "react";
import useGet from "api/useGet";
import { useNavigate, useParams } from "react-router-dom";
import {BottomErrMsg, Popup, ToastPopup} from "component/basic/popup";
import {ContentsSection} from "component/app/items";
import { Header } from "component/elements/header";
import {SettingItem} from "../../component/basic/toggle";
import {BtnBox, BtnItem} from "../../component/basic/btns";
import {OptionSection, ProductDetailInfo_subTextBox, ProductItem} from "../../component/product/detail";
import {ReviewData} from "../../component/review/detail";
import {comFormat} from "../../js/function";
import * as fatchSet from "../../api/api";
import {DatePicer} from "../../component/basic/formItems";
import moment from "moment";
import getDiscountPrice from "../../component/product/getDiscountPrice"

const Main = (props) => {
    const id = useParams().id;
    const navigate = useNavigate();
    const box = useRef(null);

    const apperanceArray = {'겔': 'gel', '과립': 'granule', '바': 'bar', '분말': 'powder', '시럽': 'syrup', '액상': 'liquid', '정제': 'tablet', '젤리': 'jelly', '캡슐': 'capsule', '페이스트': 'paste', '편상': 'flat', '필름': 'film', '환': 'pill'};

    const [selectMenu,setSelectMenu] =  useState('description');

    const [token,setToken] = useState(localStorage.getItem("token") ? true : false);
    const [popupData,setPopupData] =  useState(null);

    const [update,setUpdate] =  useState(1);
    const [myFit, setMyFit] =  useState(false);
    const [options,setOptions] =  useState([]);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [bottomOpen,setBottomOpen] = useState(false);
    const [bottomData,setBottomData] = useState({});

    const [step,setStep] =  useState(1);
    const [selectDate,setSelectDate] =  useState('');
    const [productDetailOpen,setProductDetailOpen] =  useState(false);
    const [originalProduct,setOriginalProduct] =  useState('');
    const [originalProductContents,setOriginalProductContents] =  useState([]);
    const [originalReviews,setOriginalReviews] =  useState([]);
    const [originalRelationProducts,setOriginalRelationProducts] =  useState([]);

    const [productQty,setProductQty] =  useState(1);

    const productApi = useGet({url: `/product/shop/product-detail?registrationNumber=${id}&divisionId=1&userCondition=${myFit ? 1 : 0}&update=${update}`, loginType:"login"});
    const optionApi = useGet({url: `/product/shop/option?registrationNumber=${id}`});


    useEffect(() => {
        if (productApi && productApi?.product) {
            setOriginalProduct(productApi?.product)
        }
        if (productApi && productApi?.content?.length > 0) {
            setOriginalProductContents(productApi?.content)
        }
        if (productApi && productApi?.reviews?.length > 0) {
            setOriginalReviews(productApi?.reviews)
        }
        if (productApi && productApi?.relationProduct?.length > 0) {
            setOriginalRelationProducts(productApi?.relationProduct)
        }
    }, [productApi]);

    const onClickEvn = () =>{
        setBottomOpen(!bottomOpen);
        setTimeout(() => {
            window.addEventListener("click",closeClick);
        }, 10);
    }

    const closeClick = useCallback(
        e => {
            let inside = box.current ? box.current.contains(e.target) : true;
            if (!inside){
                setBottomOpen(false);window.removeEventListener("click",closeClick);
            }
        }, [bottomOpen]
    );

    function handleCart() {
        var options_ = [];
        var amounts_ = [];

        if (options.length > 0) {
            options?.map((option) => {
                options_.push(option?.id)
                amounts_.push(option?.qty)
            })
        } else {
            amounts_.push(productQty)
        }

        let payload = {
            registrationNumber: parseInt(id),
            options: options_,
            amounts: amounts_
        };

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: `/cart/add`,
            success: (result) => {
                setBottomMsgData({
                    text : (amounts_.reduce((acc, current) => {return acc + current;}, 0)) + "개의 상품을 장바구니에 담았어요.",
                    chk : bottomMsgData.chk + 1
                });
            },
            err: (result) => {
                if(result.data || result.alert){
                    setBottomMsgData({
                        text : result.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function addOrder(type){
        if (optionApi?.length > 0 && options?.length > 0) {
            navigate(`/order`, {state: {type: type, options:options, selectDate: selectDate}});
        } else {
            var productPrice = originalProduct?.discountPercent ? getDiscountPrice(originalProduct?.discountPercent, originalProduct?.discountCut, originalProduct.price) : originalProduct?.price
            let oldOption = [{
                id: null,
                name: null,
                productId: id,
                productName: originalProduct?.name,
                productImageUrl: originalProduct?.imageUrl,
                productPrice: productPrice  * productQty,
                isRegular: originalProduct?.isRegular,
                add_price: (originalProduct?.discountPercent ? getDiscountPrice(originalProduct?.discountPercent, originalProduct?.discountCut, originalProduct.price) : originalProduct?.price) * productQty,
                total_price: (originalProduct?.discountPercent ? getDiscountPrice(originalProduct?.discountPercent, originalProduct?.discountCut, originalProduct.price) : originalProduct?.price) * productQty,
                discount_price: originalProduct?.discountPercent ? getDiscountPrice(originalProduct?.discountPercent, originalProduct?.discountCut, originalProduct.price) : null,
                discount_basic_price: productPrice  * productQty,
                remain_amount:originalProduct?.amount,
                qty: productQty,
                deliveryPrice: originalProduct?.deliveryPrice,
            }];
            navigate(`/order`, {state: {type: type, options:oldOption, selectDate: selectDate}});
        }
    }

    function optionSel(name,optionId,price,amount,discountPercent, discountCut){
        let oldData = [...options];
        if(oldData?.filter((el) => el?.id === optionId)[0]){
            window.errMsg("이미 선택된 옵션입니다.");
        }else{

            var productPrice = originalProduct?.discountPercent ? getDiscountPrice(discountPercent, discountCut, price) : originalProduct?.price
            oldData.push({
                id: optionId,
                name: name,
                productId: id,
                productName: originalProduct?.name,
                productImageUrl: originalProduct?.imageUrl,
                productPrice: productPrice,
                isRegular: originalProduct?.isRegular,
                add_price: discountPercent ? getDiscountPrice(discountPercent, discountCut, price) : price,
                total_price: (discountPercent ? getDiscountPrice(discountPercent, discountCut, price) : price),
                discount_price: discountPercent ? getDiscountPrice(discountPercent, discountCut, price) : null,
                discount_basic_price: price,
                remain_amount:amount,
                qty:1
            });
            setOptions(oldData);
        }
    }

    function optionDel(id,weekId,dayId){
        let oldData = [...options];
        oldData = oldData?.filter((el) => el?.id !== id);
        setOptions(oldData);
    }

    function optionCount(item,val){
        let oldData = [...options];
        if(oldData?.filter((el) => el.id === item.id)[0]){
            oldData.map((subItem, key) => {
                if (subItem.id === item.id) {
                    if (val === 'plus' && subItem?.qty + 1 > item.remain_amount) {
                        setBottomMsgData({text : originalProduct?.name + " 상품 재고가 부족합니다", chk : bottomMsgData.chk + 1})
                        return false
                    }

                    oldData[key].total_price = subItem?.add_price * (val === 'plus' ? subItem?.qty + 1 : subItem?.qty - 1)
                    oldData[key].qty =(val === 'plus' ? subItem?.qty + 1 : subItem?.qty - 1)
                }

            })
        }

        setOptions(oldData);
    }

    function ProductCount(val){
        if (originalProduct?.amount) {
            if (val === 'plus' && productQty + 1 > originalProduct?.amount) {

                setBottomMsgData({
                    text : originalProduct?.name + " 상품 재고가 부족합니다",
                    chk : bottomMsgData.chk + 1
                });

                return false;
            }

        }

        setProductQty((val === 'plus' ? productQty + 1 : productQty - 1))
    }

    function handleLike() {
        let formData = `registrationNumber=${encodeURIComponent(id)}`;

        fatchSet.FatchApi({
            type: originalProduct?.isLike === '1' ? "DELETE" : "POST",
            formDataItem: formData,
            contentType: "application/x-www-form-urlencoded",
            loginType:"login",
            url: originalProduct?.isLike === '1' ? `/product/product-like-cancel` : `/product/product-like`,
            success: (data) => {
                setUpdate(update + 1)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function handleRelationLike(product) {
        let formData = `registrationNumber=${encodeURIComponent(product?.registrationNumber)}`;

        fatchSet.FatchApi({
            type: product?.isLike === '1' ? "DELETE" : "POST",
            formDataItem: formData,
            contentType: "application/x-www-form-urlencoded",
            loginType:"login",
            url: product?.isLike === '1' ? `/product/product-like-cancel` : `/product/product-like`,
            success: (data) => {
                setUpdate(update + 1)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                func={() => {navigate(-1)}}
                pageBack={true}
                likeBtn={true}
                likeBtnCheck={originalProduct?.isLike}
                likeBtnFunc={() => handleLike()}
                homeBtn={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="productDetailBox">
                    <div className="productImageBox">
                        <img className="productImage" src={originalProduct?.imageUrl}/>
                    </div>
                    <div className="productDetailContentsBox">
                        <div className="productTagBox">
                            {originalProduct?.isRegular === 1 && (<span className="productTag fix">정기배송</span>)}
                            {originalProduct?.badge1 && <span className="productTag">{originalProduct?.badge1}</span>}
                            {originalProduct?.badge2 && <span className="productTag">{originalProduct?.badge2}</span>}
                            {originalProduct?.badge3 && <span className="productTag">{originalProduct?.badge3}</span>}
                        </div>
                        <div className="productNameBox">
                            <div className="productCompany">{originalProduct?.brand}</div>
                            <div className="productName">{originalProduct?.name}</div>
                            <div className="productReview">
                                <div className="productReviewStarBox">
                                    {Array.from({length: originalProduct?.reviewGrade}).map((_, idx) => (
                                        <img src="/assets/images/icon/starOn.svg"/>
                                    ))}
                                    {Array.from({length: 5 - originalProduct?.reviewGrade}).map((_, idx) => (
                                        <img src="/assets/images/icon/starOff.svg"/>
                                    ))}
                                </div>
                                <a href="#review" className="productReviewText">{originalProduct?.reviewCount}개 리뷰</a>
                            </div>
                        </div>
                        <div className="productPriceBox">
                            {originalProduct?.discountPercent ? (
                                <div className="originalPrice">{comFormat(originalProduct?.price)}원</div>) : ''}
                            <div className="discountPrice">
                                {originalProduct?.discountPercent ? (
                                    <span>{originalProduct?.discountPercent}%</span>) : ''}
                                <span>{originalProduct?.discountPercent ? comFormat(Math.round(originalProduct?.price / 100 * (100 - originalProduct?.discountPercent))) : comFormat(originalProduct?.price)}원</span>
                            </div>
                            <div
                                className="deliveryPrice">배송비 {originalProduct?.deliveryPrice ? comFormat(originalProduct?.deliveryPrice) : '-'}원
                            </div>
                        </div>
                    </div>
                    <div className="productHr"/>
                    <a className="productDetailContentsBox" id="description">
                        <div className="productDetailTabBox">
                            <a className="productDetailTab active" href="#description">제품설명</a>
                            <a className="productDetailTab"
                               href="#review">리뷰 {originalProduct?.reviewCount > 999 ? 999 + '+' : originalProduct?.reviewCount}</a>
                            <a className="productDetailTab" href="#material">원료정보</a>
                        </div>
                        <div className={`productContentsDetail ${!productDetailOpen ? 'fold' : ''}`}>
                            {!productDetailOpen && <div className="overlay"/>}
                            <div className={`ql-editor ql-snow`} dangerouslySetInnerHTML={{__html: originalProduct?.contents}}/>
                        </div>
                        {!productDetailOpen && (
                            <button className="detailButton primary" onClick={() => setProductDetailOpen(true)}>제품설명
                                더보기</button>)}
                    </a>
                    <div className="productHr"/>
                    <a className="productDetailContentsBox" id="review">
                        <div className="productDetailTabBox">
                            <a className="productDetailTab" href="#description">제품설명</a>
                            <a className="productDetailTab active"
                               href="#review">리뷰 {originalProduct?.reviewCount > 999 ? 999 + '+' : originalProduct?.reviewCount}</a>
                            <a className="productDetailTab" href="#material">원료정보</a>
                        </div>
                        <div className="reviewBox">
                            <div className="overlay"/>
                            {originalReviews?.map((review) => (<ReviewData review={review}/>))}
                        </div>
                        <button className="detailButton primary" onClick={() => navigate(`/review/all/${id}`)}>리뷰 전체
                            보기
                        </button>
                    </a>
                    <div className="productHr"/>
                    <a className="productDetailContentsBox" id="material">
                        <div className="productDetailTabBox">
                            <a className="productDetailTab" href="#description">제품설명</a>
                            <a className="productDetailTab"
                               href="#review">리뷰 {originalProduct?.reviewCount > 999 ? 999 + '+' : originalProduct?.reviewCount}</a>
                            <a className="productDetailTab active" href="#material">원료정보</a>
                        </div>
                        <div className="materialBox">
                            <div className="materialTitle">
                                <div className="materialLeft">
                                    기능성 원료 <span>{originalProductContents?.length}개</span>
                                    <button onClick={() => {
                                        setBottomOpen(true);
                                        setBottomData({
                                            'title': '기능성 원료란?',
                                            'contents': "식품의약품안전처에서는 영양성분과 더불어 동물실험, 인체적용시험과 같은 과학적인 근거를 평가하여 사람의 구조나 기능에 유용한 효과를 나타낼 수 있다고 인정한 원료를 고시하고 있어요.<br/><br/>그리고 이 기능성 원료가 우리 몸에서 유용할 정도의 \'양'이 포함되어 있어야 건강기능식품으로 인정받을 수 있답니다.<br/><br/>그래서 팜픽에서는 제품의 기능성 원료 함량 정보와 식품의약품안전처에서 제공하는 하루 권장 섭취량을 비교하여 알려드리고 있어요.<br/><br/>기능성 원료에 관한 자세한 정보를 알고 싶으시다면 자세히 보기 버튼을 클릭하시기를 바랍니다.<br/><br/>단, 이 정보는 기능성 원료의 이해를 돕고자 작성된 자료로, 특정 제품의 효능과 효과에 대한 내용이 아닌 기능성 원료의 건강 정보입니다.<br/><br/>따라서, 해당 정보는 어떤 판단과 의견을 대신하지 않으며 법적인 책임을 지지 않습니다."
                                        })
                                    }}><img src="/assets/images/icon/info.svg"/></button>
                                </div>
                                <div className="materialRight">
                                    나에게 맞춤
                                    <button onClick={() => {
                                        setBottomOpen(true);
                                        setBottomData({'title': '나에게 맞춤', 'contents': "나에게 맞춤"})
                                    }}><img src="/assets/images/icon/infoG.svg"/></button>
                                    <SettingItem toggleBtn={true} func={() => {
                                    }} checkedType={myFit} chkFunc={(e) => {
                                        if (localStorage.getItem("token")) {
                                            setMyFit(!myFit)
                                        } else {
                                            navigate('/login')
                                        }
                                    }} toggleId="myFit"/>
                                </div>
                            </div>
                            <div className="materialItemBox">
                                {originalProductContents?.map((content) => (
                                    <div className="materialItem">
                                        <div className="materialItemTitle">
                                            <div className="left">{content[1]}</div>
                                            <div className="right">{comFormat(content[2])}{content[3]}</div>
                                        </div>
                                        <div className="materialTagBox">
                                            {content[4] && (<div className="materialTag">{content[4]}</div>)}
                                            {content[5] && (<div className="materialTag">{content[5]}</div>)}
                                            {content[6] && (<div className="materialTag">{content[6]}</div>)}
                                        </div>

                                        <div className="materialGraph">
                                            <div className="graphBar">
                                                <div className={progressCheck(content)[0]}
                                                     style={{width: progressCheck(content)[1][0]}}></div>
                                                <span className="contentTag"
                                                      style={{left: progressCheck(content)[1][1]}}>
                                                    <img src="/assets/images/icon/contentB.svg"/>
                                                    <span>함량</span>
                                                </span>
                                                <img src="/assets/images/icon/greenIcon.svg" className="content"
                                                     style={{left: progressCheck(content)[1][2]}}/>
                                                <img src="/assets/images/icon/whiteIcon.svg" className="recommend"
                                                     style={{left: "24%"}}/>
                                                {content[9] && (
                                                    <img src="/assets/images/icon/redIcon.svg" className="danger"
                                                         style={{left: "83%"}}/>
                                                )}
                                                <span className="contentTagG" style={{left: "17%"}}>
                                                    <img src="/assets/images/icon/contentBg.svg"/>
                                                    <span>{content[8] ? '충분' : '권장'}섭취량</span>
                                                </span>
                                                {content[9] && (
                                                    <span className="contentTagG" style={{left: "76%"}}>
                                                        <img src="/assets/images/icon/contentBr.svg"/>
                                                        <span>상한섭취량</span>
                                                    </span>
                                                )}
                                            </div>
                                            <div className="contents"
                                                 dangerouslySetInnerHTML={{__html: progressCheck(content)[2]}}/>
                                        </div>
                                        <button className="detailButton" onClick={() => {
                                            window.flutter_inappwebview.callHandler('detailButton', {'materialId': content[0], "group": content[12]})
                                        }}>자세히 보기</button>
                                    </div>
                                ))}
                            </div>
                            <div className="info">
                                <div className="title">섭취시 주의사항</div>
                                <div className="contents" dangerouslySetInnerHTML={{__html: originalProduct?.cautions?.replace(/@/g, '<br/>')}}></div>
                            </div>
                            <div className="basicInfo">
                                <div className="title">기본 정보</div>
                                <div className="contents">
                                    <div className="contentsItem">
                                        <div className="contentsItemTitle">소비기한</div>
                                        <div className="contentsItemContents">{originalProduct?.expirationDate}</div>
                                    </div>
                                    <div className="contentsItem">
                                        <div className="contentsItemTitle">섭취량/방법</div>
                                        <div className="contentsItemContents">{originalProduct?.intakeMethod}</div>
                                    </div>
                                    <div className="contentsItem">
                                        <div className="contentsItemTitle">제형</div>
                                        <div className="contentsItemContents icon">
                                            {originalProduct?.apperance?.split('@')?.map((apperance) => (
                                                <>
                                                    <img
                                                        src={`/assets/images/product/${apperanceArray[apperance]}.png`}/>
                                                    <span>{apperance}</span>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="otherProduct">
                                <div className="title">
                                    <span>이 제품의 연관 제품</span>
                                    <a href="#" onClick={() => {
                                        window.flutter_inappwebview.callHandler('relationProduct', {'registrationNumber': originalProduct?.registrationNumber, "brand": originalProduct?.brand})
                                    }}><img src="/assets/images/basic/cal_right.svg"/></a>
                                </div>
                                <div className="contents">
                                    <div className="productBox nonPadding">
                                        {originalRelationProducts?.map((product) => (
                                            <div className="productItem"
                                                 onClick={() => navigate(`/product/detail/${product?.registrationNumber}`)}>
                                                <div className="productImage">
                                                    {product?.isRegular === 1 && <span className="label">정기배송</span>}
                                                    <img src={product?.imageUrl}/>
                                                    <div className="likeBox"
                                                         onClick={() => handleLike(product?.registrationNumber, product?.isLike)}>
                                                        <img className="likeIcon"
                                                             src={product?.isLike === "1" ? '/assets/images/icon/heartOn.svg' : '/assets/images/icon/heartOff.svg'}/>
                                                    </div>
                                                </div>
                                                <div className="productContents">
                                                    <div className="productName">{product?.name}</div>
                                                    <div className="productPrice">
                                                        <span>{product?.discountPercent}%</span>
                                                        <span>{product?.discountPercent ? comFormat(Math.round(product?.price / 100 * (100 - product?.discountPercent))) : comFormat(product?.price)}원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="productFooter">
                                <a className="link" href="https://pf.kakao.com/_xixlGBG">
                                    잘못된 정보 신고 <img src="/assets/images/basic/move.svg"/>
                                </a>
                                <div className="contents">
                                    <div>· 위 정보는 식품의약품안전처 데이터를 바탕으로 작성 되었으나, 최신 정보가 아니거나 오류를 포함하고 있을 수 있습니다.</div>
                                    <div>· 개인의 나이와 성별, 건강 상태에 따라 복용 방법과 권장/충분복용량, 효과 및 효능 등이 달라질 수 있으므로, 정확한 분석은 전문가와의
                                        상담을 권해드립니다.
                                    </div>
                                    <div>· 또한, 해당 정보는 건강기능식품 소비자의 제품 선택을 위한 참고 정보로, 어떠한 판단과 의견을 대신하지 않으며 법적인 책임을 지지
                                        않습니다.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    <BtnBox boxType="fixed" addClass={`indexUp fixButtonBox`}>
                        {toastPopupData ?
                            toastPopupData?.type === 'present' ? (<>
                                <button className="fixButton full" onClick={() => {
                                    addOrder('present')
                                }}>선물하기
                                </button>
                            </>) : (<>
                                {originalProduct?.isRegular === 0 ? (<>
                                    <button
                                        className="fixButton"
                                        onClick={() => {
                                            if (options.length === 0 && optionApi.length > 0) {
                                                setBottomMsgData({
                                                    text: "상품 옵션을 선택해주세요.",
                                                    chk: bottomMsgData.chk + 1
                                                });

                                                return false;
                                            }

                                            handleCart()
                                        }}
                                    >
                                        장바구니 담기
                                    </button>
                                    <button
                                        className="fixButton full"
                                        onClick={() => {
                                            if (options.length === 0 && optionApi.length > 0) {
                                                setBottomMsgData({
                                                    text: "상품 옵션을 선택해주세요.",
                                                    chk: bottomMsgData.chk + 1
                                                });

                                                return false;
                                            }

                                            if (token) {
                                                addOrder('direct')
                                            } else {
                                                setPopupData({
                                                    addClass: null,
                                                    title: "로그인이 필요한 페이지 입니다",
                                                    text: `로그인 페이지로 이동하시겠습니까?`,
                                                    closeType: true,
                                                    closeFunc: (e) => {
                                                        setPopupData(null)
                                                    },
                                                    btnFunc0: (e) => {
                                                        navigate("/login")
                                                    },
                                                    btn0: "확인",
                                                    btn0Type: "",
                                                    btn1: "닫기",
                                                    btn1Type: "line",
                                                    btnFunc1: () => {
                                                        setPopupData(null);
                                                    }
                                                });
                                            }
                                        }}
                                    >
                                        구매하기
                                    </button>
                                </>) : (
                                    <button className="fixButton full" onClick={() => {
                                        if (step === 1) {
                                            setStep(step + 1)
                                        } else if (step === 2) {
                                            addOrder('month')
                                        }
                                    }}>다음</button>
                                )}
                            </>)
                            : (<>
                                {(originalProduct?.isRegular === 0) && (
                                    <button className="presentButton" onClick={() =>
                                        setToastPopupData({
                                            addClass: "mContents",
                                            title: "옵션 선택",
                                            closeType: true,
                                            closeFuncChk: true,
                                            closeFunc: (e) => {
                                                setToastPopupData(null)
                                            },
                                            btnFunc0: (e) => {
                                            },
                                            btn0: null,
                                            btn0Type: "",
                                            btn1: null,
                                            btn1Type: "",
                                            btnFunc1: () => {
                                            },
                                            textBtn: null,
                                            textBtnFunc: () => {
                                            },
                                            type: "present"
                                        })}>
                                        <img src="/assets/images/icon/present.svg"/>
                                    </button>
                                )}
                                <button className="fixButton" onClick={() => {
                                    window.flutter_inappwebview.callHandler('compare', {'registrationNumber': originalProduct?.registrationNumber, 'brand': originalProduct?.brand, 'name': originalProduct?.name})
                                }}>비교함에 담기</button>
                                <button className="fixButton full" onClick={() => {
                                    if (token) {
                                        setToastPopupData({
                                            addClass: "mContents",
                                            title: "옵션 선택",
                                            closeType: true,
                                            closeFuncChk: true,
                                            closeFunc: (e) => {
                                                setToastPopupData(null)
                                            },
                                            btnFunc0: (e) => {
                                            },
                                            btn0: null,
                                            btn0Type: "",
                                            btn1: null,
                                            btn1Type: "",
                                            btnFunc1: () => {
                                            },
                                            textBtn: null,
                                            textBtnFunc: () => {
                                            },
                                            type: "order"
                                        });
                                    } else {
                                        setPopupData({
                                            addClass: null,
                                            title: "로그인이 필요한 페이지 입니다",
                                            text: `로그인 페이지로 이동하시겠습니까?`,
                                            closeType: true,
                                            closeFunc: (e) => {
                                                setPopupData(null)
                                            },
                                            btnFunc0: (e) => {
                                                navigate("/login")
                                            },
                                            btn0: "확인",
                                            btn0Type: "",
                                            btn1: "닫기",
                                            btn1Type: "line",
                                            btnFunc1: () => {
                                                setPopupData(null);
                                            }
                                        });
                                    }
                                }}>구매하기
                                </button>
                            </>)}
                    </BtnBox>
                </div>
                <ToastPopup
                    data={toastPopupData}
                >
                    {(step === 1 && optionApi?.length > 0) && (
                        <OptionSection
                            countAddClass={originalProduct?.isRegular === 1 ? 'hide' : ''}
                            options={optionApi && optionApi?.length > 0 ? optionApi : []}
                            optionArr={options}
                            optionSelFunc={(name, id, price, amount, discountPercent, discountCut) => {
                                optionSel(name, id, price, amount, discountPercent, discountCut)
                            }}
                            optionDel={(id, weekId, dayId) => {
                                optionDel(id, weekId, dayId);
                            }}
                            countFunc={(item, val) => {
                                optionCount(item, val)
                            }}
                            max={originalProduct?.isRegular === 1 ? 1 : null}
                            regular={originalProduct?.isRegular === 1}
                            totalPrice={options.reduce((acc, item) => acc + item.total_price, 0)}
                        />
                    )}
                    {(step === 1 && optionApi?.length == 0) && (
                        <>
                            <ProductItem
                                productName={originalProduct?.name}
                                originalPrice={originalProduct?.price}
                                qty={productQty}
                                countFunc={(item, val) => {
                                    ProductCount(item, val)
                                }}
                                discount_price={originalProduct?.discountPercent ? getDiscountPrice(originalProduct?.discountPercent, originalProduct?.discountCut, originalProduct.price) : originalProduct?.price}
                            />
                            <div className="productDetailInfo_totalPriceBox">
                                <h3 className="productDetailInfo_totalPriceName">총 상품 금액</h3>
                                <div className="productDetailInfo_totalPriceItem">
                                    {options.length > 0 ?
                                        <h2 className="productDetailInfo_totalPrice"
                                            dangerouslySetInnerHTML={{__html: `${comFormat(options.reduce((acc, item) => acc + item.total_price, 0))}원`}}/>
                                        :
                                        <h2 className="productDetailInfo_totalPrice"
                                            dangerouslySetInnerHTML={{__html: `${originalProduct?.discountPercent ? comFormat(getDiscountPrice(originalProduct?.discountPercent, originalProduct?.discountCut, originalProduct.price) * productQty) : originalProduct?.price}원`}}/>
                                    }
                                    {originalProduct?.isRegular === 1 && <span>/월</span>}
                                </div>
                            </div>

                        </>
                    )}
                    {step === 2 && (
                        <ProductDetailInfo_subTextBox
                            addClass=""
                            name={null}
                            text={null}
                        >
                            <h3 className="productDetailInfo_subText">정기배송 일자 선택</h3>
                            <DatePicer
                                inputName=""
                                placeholder=""
                                value={selectDate}
                                minCount={false}
                                notTodaySel={true}
                                inline={true}
                                func={(date) => {
                                    setSelectDate(date)
                                }}
                                prevMonth={(date) => {
                                }}
                                nextMonth={(date) => {
                                }}
                            />
                            <div className="productDetailInfo_totalPriceBox">
                                <h3 className="productDetailInfo_totalPriceName">배송받는 주기</h3>
                                <div className="productDetailInfo_totalPriceItem">
                                    <span>매 월 </span>&nbsp;<h2
                                    className="productDetailInfo_totalPrice">{selectDate ? moment(selectDate).format("D") : '-'}일</h2>
                                </div>
                            </div>
                            <div className="productDetailInfo_totalPriceBox">
                                <h3 className="productDetailInfo_totalPriceName">총 상품 금액</h3>
                                <div className="productDetailInfo_totalPriceItem">
                                    {options.length > 0 ?
                                        <h2 className="productDetailInfo_totalPrice"
                                            dangerouslySetInnerHTML={{__html: `${comFormat(options.reduce((acc, item) => acc + item.total_price, 0))}원`}}/>
                                        :
                                        <h2 className="productDetailInfo_totalPrice"
                                            dangerouslySetInnerHTML={{__html: `${originalProduct?.discountPercent ? comFormat(getDiscountPrice(originalProduct?.discountPercent, originalProduct?.discountCut, originalProduct.price)) : originalProduct?.price}원`}}/>
                                    }
                                    {originalProduct?.isRegular === 1 && <span>/월</span>}
                                </div>
                            </div>
                        </ProductDetailInfo_subTextBox>
                    )}
                </ToastPopup>
            </ContentsSection>
            <Popup data={popupData}/>
            <div className={`bottom_optionBoxWrapper ${bottomOpen ? "active" : ""}`} ref={box}>
                <div className={`bottom_optionBox ${bottomOpen ? "active" : ""}`}>
                    <div className={`bottom_option`}>
                        <div className="title">{bottomData?.title}</div>
                        <div className={`bottom_option_close`} onClick={onClickEvn}>
                            <img src="/assets/images/basic/close.svg"/>
                        </div>
                    </div>
                    <div className="contents" dangerouslySetInnerHTML={{__html: bottomData?.contents}}/>
                </div>
            </div>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

function progressCheck(content) {
    var values;
    var color;
    var percent;
    var text;
    if (content[7] === '영양성분') {
        if (content[10]) {
            if (content[10]?.indexOf('~') !== -1) {
                // case1 권장
                values = content[10]?.split('~')
                color = getColor(1, content, values)
                text = "권장섭취구간 : " + content[10] + content[11]
            } else {
                if (content[9]) {
                    // case3 권장
                    values = content[10]
                    color = getColor(3, content, values)
                    text = "•권장 섭취량 : " + content[10] + content[11] + "<br/>상한섭취량 : " + content[9] + content[11]
                } else {
                    // case2 권장
                    values = content[10]
                    color = getColor(2, content, values)
                    text = "•권장 섭취량 : " + content[10] + content[11]
                }
            }
        } else {
            if (content[8]?.indexOf('~') !== -1) {
                // case1 충분
                values = content[8]?.split('~')
                color = getColor(1, content, values)
                text = "충분섭취구간 : " + content[8] + content[11]
            } else {
                if (content[9]) {
                    // case3 충분
                    values = content[8]
                    color = getColor(3, content, values)
                    text = "충분섭취량 : " + content[8] + content[11] + "<br/>상한섭취량 : " + content[9] + content[11]
                } else {
                    // case2 충분
                    values = content[8]
                    color = getColor(2, content, values)
                    text = "•충분 섭취량 : " + content[8] + content[11]
                }
            }
        }
    } else {
        if (content[10]?.indexOf('~') !== -1) {
            // case1 권장
            values = content[10]?.split('~')
            color = getColor(1, content, values)
            text = "권장섭취구간 : " + content[10] + content[11]
        } else {
            // case2 권장
            values = content[10]
            color = getColor(2, content, values)
            text = "•권장 섭취량 : " + content[10] + content[11]
        }
    }

    if (color === 'yellow') {
        percent = ['15%', '12%', '12%']
    } else if (color === 'green') {
        percent = ['53%', '51%', '51%']
    } else if (color === 'red') {
        percent = ['94%', '91%', '91%']
    }

    return [color, percent, text]
}

function getColor(type, content, values) {
    if (type === 1) {
        if (content[2] < values[0]) {
            return 'yellow'
        } else {
            return 'green'
        }
    } else if (type === 2) {
        if (content[2] < values) {
            return 'yellow'
        } else {
            return 'green'
        }
    } else if (type === 3) {
        if (content[2] >= content[9]) {
            return 'red'
        } else if (content[2] < values) {
            return 'yellow'
        } else {
            return 'green'
        }
    }
}

export default Main;