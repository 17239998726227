import React, {Fragment, useState} from "react";
import { useNavigate } from "react-router-dom";
import {Popup} from "../basic/popup";

function Header(headerData){
    let navigate = useNavigate();

    const [token,setToken] = useState(localStorage.getItem("token") ? true : false);
    const [popupData,setPopupData] =  useState(null);

    return (
        <div className="header">
            <div className={`headerSection ${headerData.addClass ? headerData.addClass : ""}`}>
                {!headerData.pageBack || headerData.pageBack === "" ? "" : <button type="button" className="btnBack btnHeaderLeft" onClick={(e) => headerData.func(e)}><img src={headerData?.color === 'black' ? '/assets/images/basic/leftBtnB.svg' : '/assets/images/basic/leftBtn.svg'}/></button>}
                {headerData.logo ? <button type="button" className="btnLogo btnHeaderLeft" onClick={(e) => navigate("/")}><img src="/assets/images/app/logo_min.svg"/></button> : ""}
                {headerData.carNumber ? <div className="headCarNumberBox btnHeaderLeft"><p className="headCarNumber">{headerData.carNumber}</p></div> : ""}
                {headerData.headTitle ? <p className={`headTitle ${headerData.centerTitle ? "centerTitle" : ""} ${headerData.titleClass ? headerData.titleClass : ""} ${headerData?.color}`} dangerouslySetInnerHTML={{__html:headerData.headTitle}}/> : ""}
                {headerData.rightBtns ? 
                    <div className="headerInfoBox btnHeaderRight">
                        {headerData.rightBtnsArr.map((item, i) => (
                            <Fragment key={i}>
                                {item.type !== "img" ?
                                <button type="button" className={`btnHeaderRightItem ${item.addClass ? item.addClass : ""}`} onClick={(e) => {item.func()}} dangerouslySetInnerHTML={{__html:item.contents}}></button>
                                :
                                <button type="button" className={`btnHeaderRightItem ${item.addClass ? item.addClass : ""}`} onClick={(e) => {item.func()}}><img src={item.contents}/></button>
                                }
                            </Fragment>
                        ))}
                    </div>
                    : ""
                }
                {headerData.alarmBtn ? <button type="button" className={`btnAlarmLink btnHeaderRight ${headerData.alarmType ? "active" : ""}`} onClick={(e) => {navigate("/alarm")}}><img src={`/assets/images/icon/alarm_icon_off.svg`}/></button> : ""}
                {headerData.likeBtn ? <button type="button" className="btnHomeLink btnHeaderRight btnHeaderRightLike" onClick={(e) => token ? headerData?.likeBtnFunc() : navigate("/login")}> {headerData?.likeBtnCheck === '1' ? <img src="/assets/images/icon/activeLikeBtn.svg"/> : <img src="/assets/images/icon/likeBtn.svg"/>}</button> : ""}
                {headerData.homeBtn ? <button type="button" className="btnHomeLink btnHeaderRight" onClick={(e) => {navigate("/")}}><img src="/assets/images/icon/home_icon.svg"/></button> : ""}
                {headerData.cartBtn ? <button type="button" className="btnHomeLink btnHeaderRight btnHeaderRightCart" onClick={(e) => {token ? navigate("/cart") : setPopupData({addClass:null, title:"로그인이 필요한 페이지 입니다", text:`로그인 페이지로 이동하시겠습니까?`, closeType:true, closeFunc:(e) => {setPopupData(null)}, btnFunc0:(e)=>{navigate("/login")}, btn0:"확인", btn0Type:"", btn1:"닫기", btn1Type:"line", btnFunc1:()=>{setPopupData(null);}})}}><img src={`/assets/images/icon/cart.svg`}/>{(headerData?.cartCount && headerData?.cartCount > 0) ? (<div className="headerBadgeBox"><img className="headerBadge" src="/assets/images/icon/headerBadge.svg"/><span className="headerBadgeCount">{headerData?.cartCount}</span></div>) : ''}</button> : ""}
                {headerData.cartBkBtn ? <button type="button" className="btnHomeLink btnHeaderRight btnHeaderRightCart" onClick={(e) => {token ? navigate("/cart") : setPopupData({addClass:null, title:"로그인이 필요한 페이지 입니다", text:`로그인 페이지로 이동하시겠습니까?`, closeType:true, closeFunc:(e) => {setPopupData(null)}, btnFunc0:(e)=>{navigate("/login")}, btn0:"확인", btn0Type:"", btn1:"닫기", btn1Type:"line", btnFunc1:()=>{setPopupData(null);}})}}><img src={`/assets/images/icon/cart-black.svg`}/>{(headerData?.cartCount && headerData?.cartCount > 0) ? (<div className="headerBadgeBox"><img className="headerBadge" src="/assets/images/icon/headerBadge.svg"/><span className="headerBadgeCount">{headerData?.cartCount}</span></div>) : ''}</button> : ""}
                {headerData.profileBtn ? <button type="button" className="btnHomeLink btnHeaderRight" onClick={(e) => {token ? (navigator.userAgent.match("pharmpickApp") != null ? window.flutter_inappwebview.callHandler('mypage', {}) : navigate("/mypage")) : setPopupData({addClass:null, title:"로그인이 필요한 페이지 입니다", text:`로그인 페이지로 이동하시겠습니까?`, closeType:true, closeFunc:(e) => {setPopupData(null)}, btnFunc0:(e)=>{navigate("/login")}, btn0:"확인", btn0Type:"", btn1:"닫기", btn1Type:"line", btnFunc1:()=>{setPopupData(null);}}) }}><img src="/assets/images/icon/profile.svg"/></button> : ""}
                {headerData.closeBtn ? <button type="button" className="btnBack btnHeaderRight" onClick={(e) => headerData.func(e)}><img src={'/assets/images/basic/close.svg'}/></button> : ""}
                {headerData.pageClose ? <button type="button" className="btnClose btnHeaderRight" onClick={(e) => {headerData.closeFunc(e)}}><img src="/assets/images/basic/close_w.svg"/></button> : ""}
                {headerData.children}
            </div>
            <Popup data={popupData}/>
        </div>
    );
}

export {Header};