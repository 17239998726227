import React, {useEffect, useCallback, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {BottomErrMsg} from "component/basic/popup";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import * as fatchSet from "../../api/api";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import {CustomSelect, InputItemBox} from "../../component/basic/formItems";
import moment from "moment";

const Phone = (props) => {
    const box = useRef(null);
    const navigate = useNavigate();

    const [update,setUpdate] =  useState(1);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [submitChk,setSubmitChk] =  useState(true);
    const [bottomOpen,setBottomOpen] = useState(false);

    const [btnChk,setBtnChk] =  useState(true);

    const [step, setStep] =  useState(1);
    const [name, setName] =  useState('');
    const [registerNumberFirst, setRegisterNumberFirst] =  useState('');
    const [registerNumberLast, setRegisterNumberLast] =  useState('');
    const [phone, setPhone] =  useState('');
    const [mobileCarrier, setMobileCarrier] =  useState('');
    const [encryptMOKToken, setEncryptMOKToken] =  useState('');
    const [publicKey, setPublicKey] =  useState('');

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("3:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);

    const closeClick = useCallback(
        e => {
            let inside = box.current ? box.current.contains(e.target) : true;
            if (!inside){
                setBottomOpen(false);window.removeEventListener("click",closeClick);
            }
        }, [bottomOpen]
    );

    const onClickEvn = () =>{
        setBottomOpen(!bottomOpen);
        setTimeout(() => {
            window.addEventListener("click",closeClick);
        }, 10);
    }

    useEffect(() => {
        timerStart()
    }, []);

    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");

        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }

        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("인증시간이 초과되었습니다.");
                    setCetifiedComChk(true);
                    setCetified("");

                    setBtnChk(false);
                }
            }, 1000);
        }
    }

    function handleSubmit(){
        if(submitChk){
            setSubmitChk(false);

            var userBirthday = "";
            var userGender = "";
            var userNation = "";
            if (registerNumberLast === "1") {
                userGender = "1";
                userNation = "0";
                userBirthday = "19" + registerNumberFirst;
            } else if (registerNumberLast === "3") {
                userGender = "1";
                userNation = "0";
                userBirthday = "20" + registerNumberFirst;
            } else if (registerNumberLast === "2") {
                userGender = "2";
                userNation = "0";
                userBirthday = "19" + registerNumberFirst;
            } else if (registerNumberLast === "4") {
                userGender = "2";
                userNation = "0";
                userBirthday = "20" + registerNumberFirst;
            } else if (registerNumberLast === "5") {
                userGender = "1";
                userNation = "1";
                userBirthday = "19" + registerNumberFirst;
            } else if (registerNumberLast === "7") {
                userGender = "1";
                userNation = "1";
                userBirthday = "20" + registerNumberFirst;
            } else if (registerNumberLast === "6") {
                userGender = "2";
                userNation = "1";
                userBirthday = "19" + registerNumberFirst;
            } else if (registerNumberLast === "8") {
                userGender = "2";
                userNation = "1";
                userBirthday = "20" + registerNumberFirst;
            }

            let payload = {
                "providerId": mobileCarrier,
                "userPhone": phone,
                "userName": name,
                "userBirthday": userBirthday,
                "userGender": userGender,
                "userNation": userNation,
            };

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: JSON.stringify(payload),
                loginType: "login",
                fullurl: `https://dev.gorocket.me/auth/mok-auth`,
                success: (data) => {
                    var result = JSON.parse(data);
                    setEncryptMOKToken(result['encryptMOKToken'])
                    setPublicKey(result['publicKey'])
                    setStep(5)
                    setSubmitChk(true);
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setBottomMsgData({
                            text : data.alert||"",
                            chk : bottomMsgData.chk + 1
                        });
                    }
                }
            })
        }
    }

    function handleConfirm(){
        let payload = {
            "encryptMOKToken": encryptMOKToken,
            "publicKey": publicKey,
            "authNumber": cetified,
        };

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: JSON.stringify(payload),
            loginType: "login",
            fullurl: `https://dev.gorocket.me/auth/mok-confirm`,
            success: (data) => {
                var result = JSON.parse(data);
                if (result['ci']) { // 로그인 처리
                    handlePhoneLogin(result)
                } else { //

                }
                console.log(result)
            },
            err: (data) => {
                setSubmitChk(true);
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function handlePhoneLogin(userInfo){
        localStorage.setItem("ci", userInfo['ci']);
        localStorage.setItem("phone", userInfo['userPhone']);
        localStorage.setItem("gender", userInfo['userGender']);
        localStorage.setItem("birthday", userInfo['userBirthday']);

        let payload = {
            "ci": userInfo['ci'],
            "phone": userInfo['userPhone'],
            "gender": userInfo['userGender'],
            "birthday": userInfo['userBirthday'],
            "way": "phone",
            "di": "",
            "email": null
        };

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: JSON.stringify(payload),
            loginType: "login",
            url: `/auth/phone-login`,
            success: (data) => {
                var result = JSON.parse(data);
                if (result['code'] && result['code'] === 'IS_PHONE') {
                    navigate('/login/new')
                } else if (result['code'] && result['code'] === 'CI_EXIST') {
                    localStorage.setItem("existAccount",JSON.stringify(result.linkAccountDTO));
                    navigate('/login/link')
                } else if (result?.accessToken) {
                    localStorage.setItem("token",result.accessToken);
                    localStorage.setItem("refresh_token",result.refreshToken);

                    navigate('/')
                } else if (result?.resultMsg) {
                    setBottomMsgData({
                        text : result?.resultMsg,
                        chk : bottomMsgData.chk + 1
                    });
                }
            },
            err: (data) => {
                setSubmitChk(true);
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function handleReSubmit(){
        if(submitChk){
            setSubmitChk(false);

            var userBirthday = "";
            var userGender = "";
            var userNation = "";
            if (registerNumberLast === "1") {
                userGender = "1";
                userNation = "0";
                userBirthday = "19" + registerNumberFirst;
            } else if (registerNumberLast === "3") {
                userGender = "1";
                userNation = "0";
                userBirthday = "20" + registerNumberFirst;
            } else if (registerNumberLast === "2") {
                userGender = "2";
                userNation = "0";
                userBirthday = "19" + registerNumberFirst;
            } else if (registerNumberLast === "4") {
                userGender = "2";
                userNation = "0";
                userBirthday = "20" + registerNumberFirst;
            } else if (registerNumberLast === "5") {
                userGender = "1";
                userNation = "1";
                userBirthday = "19" + registerNumberFirst;
            } else if (registerNumberLast === "7") {
                userGender = "1";
                userNation = "1";
                userBirthday = "20" + registerNumberFirst;
            } else if (registerNumberLast === "6") {
                userGender = "2";
                userNation = "1";
                userBirthday = "19" + registerNumberFirst;
            } else if (registerNumberLast === "8") {
                userGender = "2";
                userNation = "1";
                userBirthday = "20" + registerNumberFirst;
            }

            let payload = {
                "providerId": mobileCarrier,
                "userPhone": phone,
                "userName": name,
                "userBirthday": userBirthday,
                "userGender": userGender,
                "userNation": userNation,
            };

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: JSON.stringify(payload),
                loginType: "login",
                url: `/auth/mok-auth-retry`,
                success: (data) => {
                    if (JSON.parse(data)['resultMsg']) {
                        setBottomMsgData({
                            text : JSON.parse(data)['resultMsg'],
                            chk : bottomMsgData.chk + 1
                        });
                    }
                    setSubmitChk(true);
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setBottomMsgData({
                            text : data.alert||"",
                            chk : bottomMsgData.chk + 1
                        });
                    }
                }
            })
        }
    }

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                func={() => {navigate(-1)}}
                headTitle={"휴대폰 번호로 로그인"}
                centerTitle={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                {step !== 5 ? (<>
                    <div className="inputContentsBox pb-20">
                        {step >= 4 && (
                            <div className="inputItem">
                                <div className="title">통신사</div>
                                <div className="contents">
                                    <CustomSelect
                                        placeholder="통신사를 선택해주세요."
                                        value={mobileCarrier}
                                        options={[
                                            {'id': 'SKT', 'value': 'SKT'},
                                            {'id': 'KT', 'value': 'KT'},
                                            {'id': 'LGU', 'value': 'LG U+'},
                                            {'id': 'SKTMVNO', 'value': 'SKT 알뜰폰'},
                                            {'id': 'KTMVNO', 'value': 'KT 알뜰폰'},
                                            {'id': 'LGUMVNO', 'value': 'LG U+ 알뜰폰'},
                                        ]}
                                        func={(value, id) => { setMobileCarrier(id) }}
                                        valKey="id"
                                        nameKey="value"/>
                                </div>
                            </div>
                        )}
                        {step >= 3 && (
                            <div className="inputItem">
                                <div className="title">휴대폰 번호</div>
                                <div className="contents">
                                    <InputItemBox
                                        inputType="text"
                                        placeholder="휴대폰 번호를 입력해주세요. (- 없이 숫자만)"
                                        value={phone}
                                        max={null}
                                        regexp={null}
                                        func={(value)=>{ if (value?.length >= 11 && step === 3) { setStep(4); } setPhone(value) }}
                                    />
                                </div>
                            </div>
                        )}
                        {step >= 2 && (
                            <div className="inputItem">
                                <div className="title">주민등록번호</div>
                                <div className="contents flex content-center">
                                    <InputItemBox
                                        inputType="text"
                                        placeholder="주민등록번호 앞자리"
                                        boxAddClass="col2"
                                        value={registerNumberFirst}
                                        max={6}
                                        regexp={null}
                                        func={(value)=>{ if (registerNumberFirst?.length >= 6 && registerNumberLast?.length >= 1) { setStep(3) } setRegisterNumberFirst(value) }}
                                    />
                                    <div>-</div>
                                    <InputItemBox
                                        inputType="text"
                                        placeholder="뒷"
                                        boxAddClass="col2 oneColumn"
                                        value={registerNumberLast}
                                        max={1}
                                        regexp={null}
                                        useEnter={true}
                                        func={(value)=>{ if (registerNumberFirst?.length >= 6 && registerNumberLast?.length >= 1) { setStep(3) } setRegisterNumberLast(value) }}
                                    />
                                    <div style={{ width: "29%" }}>******</div>
                                </div>
                            </div>
                        )}
                        <div className="inputItem">
                            <div className="title">이름</div>
                            <div className="contents">
                                <InputItemBox
                                    inputType="text"
                                    placeholder="이름을 입력해주세요."
                                    value={name}
                                    max={null}
                                    regexp={null}
                                    useEnter={true}
                                    enterFunc={() => {
                                        if (step === 1) {
                                            setStep(2);
                                        }
                                    }}
                                    func={(value)=>{ setName(value) }}
                                />
                            </div>
                        </div>
                    </div>
                    {(step === 4 && mobileCarrier) && (
                        <div className="pageBtn_box indexUp fixButtonBox">
                            <button className="fixButton full" onClick={() => setBottomOpen(true)}>인증번호 받기</button>
                        </div>
                    )}
                </>) : (
                    <div className="inputContentsBox pb-20">
                        <div className="inputItem">
                            <div className="title">인증번호 입력</div>
                            <div className="contents flex">
                                <InputItemBox
                                    boxStyle={{ width: "70%" }}
                                    inputType={null}
                                    addClass="cetifiedInput"
                                    inputName="인증 번호"
                                    placeholder="6자리 입력"
                                    value={cetified}
                                    max={6}
                                    readOnly={cetifiedComChk}
                                    cetified={timerCount}
                                    regexp={"number"}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    func={(value)=>{setCetified(value)}}
                                    errMsg={certifyErr}
                                />
                                <button className="fixButton" style={{ margin: "8px 0 0 10px", height: 50 }} onClick={() => handleReSubmit()}>다시 받기</button>
                            </div>
                        </div>
                        <button className={`fixButton full ${cetified?.length === 0 ? 'gray' : ''}`} onClick={() => { setBottomOpen(false); handleConfirm() }} style={{ height: 50 }}>확인</button>
                    </div>
                )}
                <div className={`bottom_optionBoxWrapper ${bottomOpen ? "active" : ""}`} ref={box}>
                    <div className={`bottom_optionBox ${bottomOpen ? "active" : ""}`} style={{ gap: 2 }}>
                        <div className={`bottom_option`}>
                            <div className="title">인증번호 발송을 위해 약관에<br/> 동의해주세요</div>
                            <div className={`bottom_option_close`} onClick={onClickEvn}>
                                <img src="/assets/images/basic/close.svg"/>
                            </div>
                        </div>
                        <button type="button" className={`bottom_option`} style={{ height: 30, minHeight: "unset" }}>
                            <div className="bottom_optionContents" style={{ padding: "30px 0", fontWeight: 600 }}>본인인증 이용약관</div>
                        </button>
                        <button type="button" className={`bottom_option`} style={{ height: 30, minHeight: "unset" }} onClick={()=>{ window.location.href = "https://parallel-crib-8b3.notion.site/SKT-659bb629a18247c7ad0a07bf636d0b3d" }}>
                            <div className="bottom_optionContents" style={{ color: "#a3a3a3", fontSize: 12 }}>개인정보 수집이용 동의(필수) ></div>
                        </button>
                        <button type="button" className={`bottom_option`} style={{ height: 30, minHeight: "unset" }} onClick={()=>{ window.location.href = "https://parallel-crib-8b3.notion.site/SKT-e16ca54f8f7842d291c29c9f071896fb?pvs=4" }}>
                            <div className="bottom_optionContents" style={{ color: "#a3a3a3", fontSize: 12 }}>고유식별정보 처리 동의(필수) ></div>
                        </button>
                        <button type="button" className={`bottom_option`} style={{ height: 30, minHeight: "unset" }} onClick={()=>{ window.location.href = "https://parallel-crib-8b3.notion.site/SKT-5ea3ed092b1f4ec8905344d9bbf6b7d0?pvs=4" }}>
                            <div className="bottom_optionContents" style={{ color: "#a3a3a3", fontSize: 12 }}>통신사 이용약관 동의(필수) ></div>
                        </button>
                        <button type="button" className={`bottom_option`} style={{ height: 30, minHeight: "unset" }} onClick={()=>{ window.location.href = "https://parallel-crib-8b3.notion.site/cb1925b426e444edb543a71ed3fa3547" }}>
                            <div className="bottom_optionContents" style={{ color: "#a3a3a3", fontSize: 12 }}>서비스 이용약관 동의(필수) ></div>
                        </button>
                        <button className="fixButton full" onClick={() => { setBottomOpen(false); handleSubmit() }} style={{ margin: "20px 20px 0 20px" }}>전체 동의하고 인증번호 받기</button>
                    </div>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Phone;