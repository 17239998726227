import React, { cloneElement, useEffect, useState } from "react";
import {BtnBox, BtnItem, PopupBtnBox} from "./btns";
import * as fatchSet from "../../api/api";
import { useRef } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { bodyScroll_clear, bodyScroll_limit, comFormat } from "js/function";
import useGet from "api/useGet";
import { Fragment } from "react";
import { ChkBox } from "./formItems";
import {BoardCard, ContentsSection, PageBody, PageSubHeader} from "../app/items";
import {useNavigate} from "react-router-dom";
import moment from "moment";

//우측 슬라이드 팝업
function RightPopup(data){
    const navigate = useNavigate();

    const [popupOpen,setPopupOpen] =  useState("block");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");

    useEffect(()=>{
        if(data.openType){
            setPopupData(data.openType)
            bodyScroll_limit();
        }else{
            setPopupData(null)
            bodyScroll_clear();
        }
    },[data.openType]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                // setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 200);
            }, 200);
            bodyScroll_limit();
        }else{
            setPopupClass("");
            setTimeout(() => {
                // setPopupOpen("none");
            }, 200);
            bodyScroll_clear();
        }
    },[popupData]);

    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".rightPopup .header")[0].clientHeight,
            cardSize = document.querySelectorAll(".rightPopup .card")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (headerSize + cardSize)

        document.querySelectorAll(".rightPopup .pageBody")[0].style.height = `${totalSize}px`;
    }

    useEffect(() => {
        handleResize()
    }, [data]);

    return (
        <div className={`rightPopup ${popupClass ? popupClass : ""} ${data.addClass ? data.addClass : ""} ${data.bType ? "bPopup" : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {setPopupData(null);data.closePopup && data.closePopup(e)}}></div>
            <div className="header">
                <div className="headerSection pageMainBg">
                    <div className="btnHomeLink btnHeaderLeft">
                        <button onClick={() => {
                            if (navigator.userAgent.match("pharmpickApp") != null) {
                                window.flutter_inappwebview.callHandler('mypage', {})
                            } else {
                                navigate('/mypage');
                                window.rightClose()
                            }
                        }}><img src="/assets/images/basic/setting.svg"/></button>
                        <button onClick={() => { window.logOut(); navigate(`/login`); window.rightClose() }}><img src="/assets/images/basic/logout.svg"/></button>
                    </div>
                    <button type="button" className="btnHomeLink btnHeaderRight" onClick={() => { window.rightClose() }}>
                        <img src="/assets/images/basic/grayClose.svg"/>
                    </button>
                </div>
            </div>
            <ContentsSection addClass="headerContents pageMainBg">
                <div className="card" style={{ cursor: "auto" }}>
                    <div>
                        <div className="cardNameBox">
                            {data?.userInfo?.status === 0 ? (<div className="categoryTag redTag m-0">근무중</div>) : ''}
                            {data?.userInfo?.company_name}
                        </div>
                        <div className="cardEmployeeBox">
                            <div className="title">{data?.userInfo?.name}</div>
                            <div className="contents">{data?.userInfo?.begin_date} ~ {data?.userInfo?.end_date}</div>
                        </div>
                    </div>
                    <button onClick={() => { navigate('/'); window.rightClose() }}><img className="smallIcon" src="/assets/images/basic/modify.svg"/></button>
                </div>
                <PageBody>
                    <div className="menuBox">
                        {data?.userInfo?.end_date < moment().format('YYYY-MM-DD')
                            ?
                                <div className="item" onClick={() => {navigate('/document'); window.rightClose()}}><img className="icon" src="/assets/images/icon/document.svg"/> 문서관리</div>
                            :
                                <>
                                    {(data?.userInfo?.workflow === 1 || data?.userInfo?.type === 2) && (<div className="item" onClick={() => { navigate('/workflow'); window.rightClose() }}><img className="icon" src="/assets/images/icon/workflow.svg"/> 워크플로우</div>)}
                                    {(data?.userInfo?.temp_worker === 1 || data?.userInfo?.type === 2) && (<div className="item" onClick={() => { navigate('/tempWorker'); window.rightClose() }}><img className="icon" src="/assets/images/icon/tempWorker.svg"/> 일시근무자</div>)}
                                    {data?.userInfo?.type !== 2 && <div className="item" onClick={() => { navigate('/workHistory'); window.rightClose() }}><img className="icon" src="/assets/images/icon/workHistory.svg"/> 출퇴근 기록</div>}
                                    <div className="item" onClick={() => { navigate('/todo'); window.rightClose() }}><img className="icon" src="/assets/images/icon/todo.svg"/> 할 일</div>
                                    <div className="item" onClick={() => { navigate('/schedule'); window.rightClose() }}><img className="icon" src="/assets/images/icon/schedule.svg"/> 스케줄</div>
                                    {data?.userInfo?.type !== 2 && (
                                        <>
                                            <div className="item" onClick={() => { navigate('/additional'); window.rightClose() }}><img className="icon" src="/assets/images/icon/additional.svg"/> 추가수당</div>
                                            <div className="item" onClick={() => { navigate('/dayoff'); window.rightClose() }}><img className="icon" src="/assets/images/icon/dayoff.svg"/> 휴가관리</div>
                                            <div className="item" onClick={() => { navigate('/document'); window.rightClose() }}><img className="icon" src="/assets/images/icon/document.svg"/> 문서관리</div>
                                            <div className="item" onClick={() => { navigate('/salary'); window.rightClose() }}><img className="icon" src="/assets/images/icon/salary.svg"/> 급여정보</div>
                                        </>
                                    )}
                                    <div className="item" onClick={() => { navigate('/board'); window.rightClose() }}><img className="icon" src="/assets/images/icon/board.svg"/> 게시판</div>
                                </>
                        }
                    </div>
                </PageBody>
            </ContentsSection>
        </div>
    );
}

//로딩
function LoadingBox(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupClass,setPopupClass] =  useState(null);

    useEffect(()=>{
        if(data.openType){
            setPopupOpen("block");
            setPopupClass("active");
            bodyScroll_limit();
        }else{
            setPopupClass("");
            setPopupOpen("none");
            bodyScroll_clear();
        }
    },[data.openType]);

    return (
        <div className={`loadingBox ${popupClass||""} ${data.addClass||""}`} style={{display:popupOpen}}>
            <div className="popup_cover"></div>
            <div id="loading" className="loading">
                <div className="loading_dotBox">
                    <div className="loading_dot loading_dot1"><i></i></div>
                    <div className="loading_dot loading_dot2"><i></i></div>
                    <div className="loading_dot loading_dot3"><i></i></div>
                    <div className="loading_dot loading_dot4"><i></i></div>
                    <div className="loading_dot loading_dot5"><i></i></div>
                    <div className="loading_dot loading_dot6"><i></i></div>
                    <div className="loading_dot loading_dot7"><i></i></div>
                    <div className="loading_dot loading_dot8"><i></i></div>
                    <div className="loading_dot loading_dot9"><i></i></div>
                </div>
            </div>
        </div>
    );
}

//하단 메세지 문구
let timeOut = "";
function BottomErrMsg(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");

    useEffect(()=>{
        if(data.text && data.text !== ""){
            clearTimeout(timeOut);

            setPopupData(data.text);
            
            setPopupOpen("block");
            setTimeout(() => {
                setPopupClass("active");
            }, 100);

            timeOut = setTimeout(() => {
                setPopupClass("");
                setTimeout(() => {
                    setPopupOpen("none");
                }, 200);
            }, 2000);
        }
    },[data.chk]);

    return (
        <p className={`bottomErrMsg ${popupClass ? popupClass : ""} ${data.addClass ? data.addClass : ""}`} style={{display:popupOpen}} dangerouslySetInnerHTML={{__html:popupData}}></p>
    );
}

//기본팝업
function Popup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
            bodyScroll_limit();
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
            bodyScroll_clear();
        }
    },[popupData]);

    return (
        <div className={`popup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFunc && popupData?.closeFunc(e)}}/>
            <div className="popupBox">
                <img
                    src={data?.data?.confirm ? '/assets/images/icon/popupConfirmIcon.svg' : '/assets/images/icon/popupIcon.svg'}
                    className={(data?.data?.title === '구독 종료 안내' || data?.data?.title === '리뷰 작성 안내') ? "popupIconRegular" : "popupIcon"}/>
                <div className="popupSetion">

                    <div className="popupTextBox">
                        {popupData?.title ?
                            <h2 className="popupTitle" dangerouslySetInnerHTML={{__html: popupData?.title}}/> : ""}
                        <p className="popupText" dangerouslySetInnerHTML={{__html: popupData?.text}}/>
                        {data?.children}
                    </div>
                    <div className={`popup_btnBox`}>
                        <button type="button"
                                className={`btn_popup btn_popupLeft ${popupData?.btn0Type ? popupData?.btn0Type : ""}`}
                                onClick={(e) => {
                                    setPopupData(null);
                                    popupData?.btnFunc0(e)
                                }} dangerouslySetInnerHTML={{__html: popupData?.btn0}}/>
                        {popupData?.btn1 ?
                            <>
                                {popupData?.btn1Type == "link" ?
                                    <a href={popupData?.btn1Link} target="_blank"
                                       className={`btn_popup btn_popupRight ${popupData?.btn1Type ? popupData?.btn1Type : ""}`}
                                       onClick={(e) => {
                                           setPopupData(null);
                                           popupData?.btnFunc1(e)
                                       }} dangerouslySetInnerHTML={{__html: popupData?.btn1}}/>
                                    :
                                    <button type="button"
                                            className={`btn_popup btn_popupRight ${popupData?.btn1Type ? popupData?.btn1Type : ""}`}
                                            disabled={popupData?.btn1Disabled ? popupData?.btn1Disabled : false}
                                            onClick={(e) => {
                                                setPopupData(null);
                                                popupData?.btnFunc1(e)
                                            }} dangerouslySetInnerHTML={{__html: popupData?.btn1}}/>
                                }
                            </>
                            : ""}
                    </div>
                </div>
            </div>
        </div>
    );
}

//토스트팝업
function ToastBasicContents(data) {
    return (
        <div className={`toastBasicContents ${data?.subItem ? "toastBasicContents_subItemCom" : ""}`}>
            <div className="toastBasicContents_titleBox">
                {data.title ? <h1 className="toastBasicContents_title" dangerouslySetInnerHTML={{__html:data?.title}}/> : ""}
                {data.text ? <p className="toastBasicContents_text" dangerouslySetInnerHTML={{__html:data?.text}}/> : ""}
            </div>
            {data?.subItem ? <p className="toastBasicContents_subItem" dangerouslySetInnerHTML={{__html:data?.subItem}}/> : ""}
        </div>
    );
}

function ToastPopup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    const [chkVal,setChkVal] =  useState(popupData?.chkType||false);
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
            bodyScroll_limit();
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
            bodyScroll_clear();
        }
    },[popupData]);

    return (
        <div className={`toastPopup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFuncChk && popupData?.closeFunc(e)}}/>
            <div className="popupSetion">
                {/*                 
                {popupData?.title || popupData?.closeFuncChk || data.header ?
                <div className="popupHaeder">
                    {data.header||""}
                    {popupData?.headBtn ? <button type="button" className="popupHeadBtn" onClick={(e) => {popupData?.headBtnFunc(e)}}>{popupData?.headBtn}</button> : ""}
                    {popupData?.title ? <h2 className="popupHaederTitle" dangerouslySetInnerHTML={{__html:popupData?.title}}/> : ""}
                    {popupData?.textBtn ? <button type="button" className="textBtn" onClick={(e) => {setPopupData(null);popupData.textBtnFunc()}} dangerouslySetInnerHTML={{__html:popupData?.textBtn}}/> : ""}
                </div> : ""} 
                */}
                <div className="popupContents">
                    {data.children && data.children}
                </div>
                {popupData?.btnChkBox ? 
                    <div className="popup_btnChkBox">
                        <ChkBox
                            addClass=""
                            func={(e) => {popupData?.btnChkFunc(e.target.checked);setChkVal(e.target.checked)}}
                            label={popupData?.btnChkFuncLabel}
                            chkFunc={(e) => popupData?.btnChkClickFunc()}
                            checkedType={popupData?.chkType||chkVal}
                        />
                    </div>
                :""}
                {popupData?.btn0 ? 
                <div className={`popup_btnBox toastPopup_btnBox ${popupData?.btn1 ? "col2" : ""}`}>
                    <button type="button" className={`btn_popup btn_popupLeft ${popupData?.btn0Type ? popupData?.btn0Type : ""}`} onClick={(e) => {setPopupData(null);popupData?.btnFunc0(e)}} disabled={popupData?.btn0Disabled ? popupData?.btn0Disabled : false} dangerouslySetInnerHTML={{__html:popupData?.btn0}}/>
                    {popupData?.btn1 ? <button type="button" disabled={popupData?.btn1Disabled ? popupData?.btn1Disabled : false} className={`btn_popup btn_popupRight ${popupData?.btn1Type ? popupData?.btn1Type : ""}`} onClick={(e) => {setPopupData(null);popupData?.btnFunc1(e)}} dangerouslySetInnerHTML={{__html:popupData?.btn1}}/> : ""}
                </div>
                :""}
            </div>
        </div>
    );
}

//선택팝업
function SelPopup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
            bodyScroll_limit();
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
            bodyScroll_clear();
        }
    },[popupData]);

    return (
        <div className={`selPopup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFuncChk && popupData?.closeFunc(e)}}></div>
            <div className="selPopupSetion">
                <div className="popupHaeder">
                    {popupData?.title ? <h2 className="popupHaederTitle" dangerouslySetInnerHTML={{__html:popupData?.title}}/> : ""}
                    {/* <button type="button" className="popupClose" onClick={(e) => {setPopupData(null);popupData?.closeFuncChk && popupData.closeFunc(e)}}><img src={`/assets/images/basic/close.svg`} alt="back_icon"/></button> */}
                </div>
                <div className="popupContents">
                    {data.children && data.children}
                </div>
                <div className="selPopup_selBox yScroll">
                    {popupData?.dataKey ? 
                        popupData?.data?.length > 0 ?
                        popupData?.data.map((item, i) => (
                                <button type="button" key={i} className={`selPopup_sel ${item[popupData?.dataKey] == popupData?.sel ? "active" : ""}`} onClick={(e) => {setPopupData(null);popupData?.func({val:item.id, text:item[popupData?.dataKey]})}} dangerouslySetInnerHTML={{__html:item[popupData?.dataKey]}}></button>
                            ))
                            : ""
                        : 
                        popupData?.items?.map((item, i) => (
                            popupData?.val[i] == "notSel" ?
                            <p key={i} className="selPopup_selSub_category" dangerouslySetInnerHTML={{__html:item}}></p>
                            :
                            popupData?.val[i] == "lineItem" ?
                            <p key={i} className="selPopup_selSub_lineItem"></p>
                            :
                            <button type="button" key={i} className={`selPopup_sel ${item == popupData?.sel || popupData?.val[i] == popupData?.sel ? "active" : ""}`} onClick={(e) => {setPopupData(null);popupData?.func({val:popupData?.val[i], text:item})}} dangerouslySetInnerHTML={{__html:item}}></button>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}


function SlideUpPopup(data){
    const [moveType,setMoveType] =  useState("");
    const [startEvent,setStartEvent] =  useState(false);
    const [startDownEvent,setStartDownEvent] =  useState(false);

    const [popupActiveClass,setPopupActiveClass] =  useState("");
    const [coverActiveClass,setCoverActiveClass] =  useState("");
    const [popupOpenClass,setPopupOpenClass] =  useState("");
    const [popupTransform,setPopupTransform] =  useState("translateY(calc(100% - 82px))");

    const titleBox = useRef(null);
    const iconBox = useRef(null);

    const [startInfo,setStartInfo] =  useState({
        startX: -1,
        startY: -1,
        startTime: 0,
        start_point: 0
    });

    //upType 구분
    function getMoveTypeUp(x, y){
        let reMoveType = {
        type: "down",
        y: 0,
        };
    
        let nY = startInfo.startY - y;
        reMoveType.y = nY <= 0 ? 0 : nY;
    
        if(nY >= 80){
        reMoveType.type = "up";
        }
    
        return reMoveType;
    }

    //downType 구분
    function getMoveTypeDown(x, y){
        let reMoveType = {
        type: "up",
        y: 0,
        };
    
        let nY = startInfo.startY - y;
            reMoveType.y = nY >= 0 ? 0 : nY;
        if(nY <= -150){
            reMoveType.type = "down";
        }
    
        return reMoveType;
    }

    //초기화
    function initTouchInfo(){
        let startSet = {
            startX: -1,
            startY: -1,
            startTime: 0,
            start_point: 0
        }

        setStartInfo(startSet);
    }

    //슬라이드 팝업(강제)
    function slidePopupUp(){
        setStartEvent(false);
        initTouchInfo();
        setMoveType("");

        setPopupActiveClass("active");
        setCoverActiveClass("active");
        setPopupTransform("translateY(0)");
        setPopupOpenClass("open");
    }
  
    function slidePopupDown(){
        setPopupActiveClass("active");

        setStartDownEvent(false);
        initTouchInfo();
        
        setMoveType("");
        setPopupOpenClass("");
        setCoverActiveClass("");
        setPopupTransform("translateY(calc(100% - 82px))");

        setTimeout(() => {
            setPopupActiveClass("");
        }, 500);
    }

    //up event
    function upTouchstart(e){
        if(popupOpenClass == ""){
            if(titleBox.current.getBoundingClientRect().top > 70){
                let startSet = {
                    startX: e.touches[0].pageX,
                    startY: e.touches[0].pageY,
                    startTime: e.timeStamp,
                    start_point: e.touches[0].pageY
                }
            
                setStartInfo(startSet);
                setStartEvent(true);
            }

            bodyScroll_limit();
        }
    }

    function upTouchmove(e){
        if(startEvent && popupOpenClass == ""){
            var moveX = e.touches[0].pageX;
            var moveY = e.touches[0].pageY;
            var moveDis = moveX + moveY;
            setMoveType(getMoveTypeUp(moveX, moveY));
            setPopupTransform(`translateY(calc(100% - ${82 + getMoveTypeUp(moveX, moveY).y}px))`);
            
            if(moveType.type == "up"){
                setStartEvent(false);
                initTouchInfo();
                setMoveType("");

                setPopupActiveClass("active");
                setCoverActiveClass("active");
                setPopupTransform("translateY(0)");
                setPopupOpenClass("open");
            }
        }
    }

    function upTouchend(e){
        if(popupOpenClass == ""){
            if(startEvent){
                if(moveType.y >= 70){
                    setPopupActiveClass("active");
                    setCoverActiveClass("active");
                    setPopupTransform("translateY(0)");
                    setPopupOpenClass("open");

                    bodyScroll_limit();
                }else{
                    setPopupActiveClass("active");
                    setPopupTransform("translateY(calc(100% - 82px))");
                    setCoverActiveClass("");
                    setTimeout(() => {
                        setPopupActiveClass("");
                    }, 500);

                    bodyScroll_clear();
                }

                setMoveType("");
                setStartEvent(false);
                initTouchInfo();
            }else{
                bodyScroll_clear();
            }
        }
    }

    //down event
    function downTouchstart(e){
        if(iconBox.current.getBoundingClientRect().top >= 70 && popupOpenClass=="open"){
            let startSet = {
                startX: e.touches[0].pageX,
                startY: e.touches[0].pageY,
                startTime: e.timeStamp,
                start_point: e.touches[0].pageY
            }

            setStartInfo(startSet);
            setStartDownEvent(true);
        }
    }

    function downTouchmove(e){
        if(startDownEvent && popupOpenClass=="open"){
            var moveX = e.touches[0].pageX;
            var moveY = e.touches[0].pageY;
            var moveDis = moveX + moveY;
            setMoveType(getMoveTypeDown(moveX, moveY));

            setPopupTransform(`translateY(${Math.abs(getMoveTypeDown(moveX, moveY).y)}px)`);

            if(moveType.type == "down"){
                setPopupActiveClass("active");
                setStartDownEvent(false);
                initTouchInfo();
                setMoveType("");

                setPopupOpenClass("");
                setCoverActiveClass("");
                setPopupTransform("translateY(calc(100% - 82px))");

                setTimeout(() => {
                    setPopupActiveClass("");
                }, 500);
            }
        }
    }

    function downTouchend(e){
        if(startDownEvent && popupOpenClass=="open"){
            if(Math.abs(moveType.y) >= 70){
                setPopupOpenClass("");
                setCoverActiveClass("");
                setPopupTransform("translateY(calc(100% - 82px))");

                setTimeout(() => {
                    setPopupActiveClass("");
                }, 500);
            }else{
                setPopupActiveClass("active");
                setPopupTransform("translateY(0)");
            }
            setMoveType("");
            setStartDownEvent(false);

            initTouchInfo();
        }
    }

    useEffect(()=>{
        if(popupActiveClass == "active" || popupOpenClass=="open"){
            bodyScroll_limit();
        }else{
            bodyScroll_clear();
        }
    },[popupActiveClass, popupOpenClass]);

    useEffect(()=>{
        slidePopupDown();
    },[data.slideUpPopupClose]);
    
    return (
        <>
            <div className={`slideUpPopup_cover ${coverActiveClass||""}`} onClick={()=>{slidePopupDown()}}></div>
            <div className={`slideUpPopup ${data?.addClass ? data?.addClass : ""} ${popupActiveClass||""} ${popupOpenClass||""}`} style={{transform:popupTransform}}>
                <div className="slideUpPopup_section" onTouchStart={(e)=>{if(popupActiveClass == "active"){downTouchstart(e)}else{upTouchstart(e)}}} onTouchMove={(e)=>{if(popupActiveClass == "active"){downTouchmove(e)}else{upTouchmove(e)}}} onTouchEnd={(e)=>{if(popupActiveClass == "active"){downTouchend(e)}else{upTouchend(e)}}}>
                    <div className="scroll_sel_icon" ref={iconBox}>
                        <img src={`/assets/images/basic/${popupOpenClass == "" ? "slideUp_icon_off" : "slideUp_icon_on"}.svg`}/>
                    </div>
                    <div className="slideUpPopup_titleBox" ref={titleBox}>
                        {data.title}
                    </div>
                    <div className="slideUpPopup_contents">
                        {data.children}
                    </div>
                </div>
            </div>
        </>
    );
}

//이미지 뷰 팝업
function ImagePopup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
            bodyScroll_limit();
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
            bodyScroll_clear();
        }
    },[popupData]);

    return (
        <div className={`popup imagePopup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFunc && popupData?.closeFunc(e)}}/>
            <div className={`imagePopupSetion ${popupData?.btn0 ? "imagePopupBtnSetion" : ""}`}>
                <button type="button" className="btn_imagePopupClose" onClick={(e) => {setPopupData(null);popupData?.closeFunc && popupData?.closeFunc(e)}}><img src="/assets/images/basic/close.svg"/></button>
                <div className="imagePopup_imgArea">
                    {popupData?.img ? <img src={popupData?.img}/> : ""}
                </div>
                {popupData?.btn0 && (
                    <div className={`popup_btnBox ${popupData?.btn1 ? "col2" : ""}`}>
                        <button type="button" className={`btn_popup btn_popupLeft ${popupData?.btn0Type ? popupData?.btn0Type : ""}`} onClick={(e) => {setPopupData(null);popupData?.btnFunc0(e)}} dangerouslySetInnerHTML={{__html:popupData?.btn0}}/>
                        {popupData?.btn1 ?
                            <>
                                {popupData?.btn1Type == "link" ?
                                    <a href={popupData?.btn1Link} target="_blank" className={`btn_popup btn_popupRight ${popupData?.btn1Type ? popupData?.btn1Type : ""}`} onClick={(e) => {setPopupData(null);popupData?.btnFunc1(e)}} dangerouslySetInnerHTML={{__html:popupData?.btn1}}/>
                                    :
                                    <button type="button" className={`btn_popup btn_popupRight ${popupData?.btn1Type ? popupData?.btn1Type : ""}`} disabled={popupData?.btn1Disabled ? popupData?.btn1Disabled : false} onClick={(e) => {setPopupData(null);popupData?.btnFunc1(e)}} dangerouslySetInnerHTML={{__html:popupData?.btn1}}/>
                                }
                            </>
                            : ""}
                    </div>
                )}
            </div>
        </div>
    );
}

export {Popup, ToastPopup, SelPopup, LoadingBox, RightPopup, BottomErrMsg, ToastBasicContents, SlideUpPopup, ImagePopup}