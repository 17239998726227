import React, {useEffect, useCallback, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {BottomErrMsg} from "component/basic/popup";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import * as fatchSet from "../../api/api";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import {CustomSelect, InputItemBox} from "../../component/basic/formItems";
import moment from "moment/moment";
import useGet from "../../api/useGet";

const JoinComplete = (props) => {
    const navigate = useNavigate();

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const userApi = useGet({url: `/user/select-userInfo`, loginType:"login"});

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                func={() => {navigate(-1)}}
                centerTitle={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="loginBox">
                    <div className="loginTitleBox">
                        <div className="loginTitle">{userApi?.nickname}님,<br/>가입을 축하합니다 !</div>
                        <div className="loginContents">나의 건강기능식품 사용설명서, 팜픽입니다.</div>
                    </div>
                    <img src="/assets/images/login/joinCom.svg"/>
                </div>
                <div className="pageBtn_box indexUp fixButtonBox">
                    <button className="fixButton full" onClick={() => navigate('/')}>확인</button>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default JoinComplete;