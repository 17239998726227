import React, {useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {BottomErrMsg, Popup} from "component/basic/popup";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import {OrderData} from "../../component/order/detail";
import {ChkBox, InputItemBox} from "../../component/basic/formItems";
import {BtnBox} from "../../component/basic/btns";
import useGet from "../../api/useGet";
import {comFormat, PhoneSetting} from "../../js/function";
import { useDaumPostcodePopup } from "react-daum-postcode";
import * as fatchSet from "../../api/api";
import moment from "moment";
import { loadTossPayments, ANONYMOUS } from "@tosspayments/tosspayments-sdk";

const Order = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pageData = location?.state ? location?.state : null;

    const clientKey = pageData?.type === 'month' ? "test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq" : "test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm";
    const customerKey = pageData?.type === 'month' ? "U6Qo78CbwM2b6mD4q_wae" : "6_PrgodvtwpeTCMnG8Sbm";

    const open = useDaumPostcodePopup();

    const [update, setUpdate] =  useState(1);
    const [deliverySave, setDeliverySave] =  useState(false);
    const [deliveryPopup, setDeliveryPopup] =  useState(false);
    const [deliveryRegisterPopup, setDeliveryRegisterPopup] =  useState(false);
    const [deliveryModifyPopup, setDeliveryModifyPopup] =  useState(false);
    const [deliveryName, setDeliveryName] =  useState('');
    const [deliveryPhone, setDeliveryPhone] =  useState('');
    const [deliveryAddress, setDeliveryAddress] =  useState('');
    const [deliveryAddressDetail, setDeliveryAddressDetail] =  useState('');
    const [deliveryPostcode, setDeliveryPostcode] =  useState('');
    const [deliveryId, setDeliveryId] =  useState('');
    const [point, setPoint] =  useState(0);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [popupData,setPopupData] =  useState(null);
    const [order,setOrder] =  useState(false);

    const [newDeliverySave, setNewDeliverySave] =  useState(false);
    const [newDeliveryId, setNewDeliveryId] =  useState('');
    const [newDeliveryDefault, setNewDeliveryDefault] =  useState(false);
    const [newDeliveryName, setNewDeliveryName] =  useState('');
    const [newDeliveryPhone, setNewDeliveryPhone] =  useState('');
    const [newDeliveryAddress, setNewDeliveryAddress] =  useState('');
    const [newDeliveryAddressDetail, setNewDeliveryAddressDetail] =  useState('');
    const [newDeliveryPostcode, setNewDeliveryPostcode] =  useState('');

    const [payment, setPayment] = useState(null);
    const [ready, setReady] = useState(false);
    const [widgets, setWidgets] = useState(null);

    const pointApi = useGet({url: `/point/total`, loginType:"login"});
    const addressApi = useGet({url: `/address?update=${update}`, loginType:"login"});
    const deliveryApi = useGet({url: `/delivery/setting?price=${pageData?.options?.reduce((acc, item) => acc + item.total_price, 0) - point}&postcode=${deliveryPostcode}${pageData?.type === 'month' ? `&type=regular` : ''}`, loginType:"login"});

    useEffect(() => {
        async function fetchPaymentWidgets() {
            const tossPayments = await loadTossPayments(clientKey);
            if (pageData?.type === 'month') {
                const payment = tossPayments.payment({
                    customerKey,
                });
                setPayment(payment);
            } else {
                const widgets = tossPayments.widgets({
                    customerKey,
                });
                setWidgets(widgets);
            }
        }

        fetchPaymentWidgets();
    }, [clientKey, customerKey]);

    const renderPaymentWidgets = async () => {
        if (widgets == null) {
            return;
        }
        // ------ 주문의 결제 금액 설정 ------
        await widgets.setAmount({currency: "KRW", value: (pageData?.options?.reduce((acc, item) => acc + item.total_price, 0) + deliveryApi?.deliveryPrice - Number(point)),});

        await Promise.all([
            // ------  결제 UI 렌더링 ------
            widgets.renderPaymentMethods({
                selector: "#payment-method",
                variantKey: "DEFAULT",
            }),
            // ------  이용약관 UI 렌더링 ------
            widgets.renderAgreement({
                selector: "#agreement",
                variantKey: "AGREEMENT",
            }),
        ]);

        setReady(true);
    }

    useEffect(() => {
        if (widgets == null) {
            return;
        }

        widgets.setAmount({currency: "KRW", value: pageData?.options?.reduce((acc, item) => acc + item.total_price, 0),});
    }, [widgets]);

    useEffect(() => {
        if (addressApi && addressApi?.length > 0 && pageData?.type !== 'present') {
            addressApi?.map((data, key) => {
                if (data?.isDefault === 1) {
                    setDeliveryId(data?.id)
                    setDeliveryName(data?.name)
                    setDeliveryPhone(data?.phone)
                    setDeliveryAddress(data?.address)
                    setDeliveryAddressDetail(data?.addressDetail)
                    setDeliveryPostcode(data?.postcode)
                }
            })
        }
    }, [addressApi]);

    const handleCompletePopup = (addrItem) => {
        setNewDeliveryAddress(addrItem?.address)
        setNewDeliveryPostcode(addrItem?.zonecode)
    };

    const handleCompleteOrder = (addrItem) => {
        setDeliveryAddress(addrItem?.address)
        setDeliveryPostcode(addrItem?.zonecode)
    };

    const addrShOpen = (dataType) => {
        if(dataType === "popup"){
            open({ onComplete: handleCompletePopup });
        }else{
            open({ onComplete: handleCompleteOrder });
        }
    };

    const handleOrder = async () => {
        if (pageData?.type !== 'present' && (!deliveryName || !deliveryPhone || !deliveryAddress || !deliveryAddressDetail || !deliveryPostcode)) {
            setBottomMsgData({
                text : "배송지를 입력해주세요",
                chk : bottomMsgData.chk + 1
            });
            return false
        } else if (pageData?.type === 'present' && !deliveryName) {
            setBottomMsgData({
                text : "받는 분 성함을 입력해주세요",
                chk : bottomMsgData.chk + 1
            });
            return false
        }

        var registrationNumber_ = [];
        var options_ = [];
        var amounts_ = [];
        pageData?.options?.map((option) => {
            registrationNumber_.push(option?.productId)
            options_.push(option?.id)
            amounts_.push(option?.qty)
        })

        let payload = {}
        if (pageData?.type === 'month') {
            payload = {
                registrationNumber: parseInt(registrationNumber_[0]),
                option: options_[0],
                recipientName: deliveryName,
                recipientPhone: deliveryPhone,
                recipientAddress: deliveryAddress,
                recipientAddressDetail: deliveryAddressDetail,
                recipientAddressPostcode: deliveryPostcode,
                addressSave: deliverySave ? 1 : 0,
                usePoint: point ? Number(point) : 0,
                paymentDate: moment(pageData?.selectDate).format("YYYY-MM-DD"),
                cartIds: pageData?.cartIds,
            };
        } else {
            payload = {
                registrationNumbers: registrationNumber_,
                options: options_,
                amounts: amounts_,
                recipientName: deliveryName,
                recipientPhone: deliveryPhone,
                recipientAddress: deliveryAddress,
                recipientAddressDetail: deliveryAddressDetail,
                recipientAddressPostcode: deliveryPostcode,
                addressSave: deliverySave ? 1 : 0,
                usePoint: point ? Number(point) : 0,
                isPresent: pageData?.type === 'present' ? 1 : 0,
                cartIds: pageData?.cartIds,
            };
        }

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: pageData?.type === 'month' ? `/order/regular-register` : `/order/register`,
            success: async (result) => {
                if (pageData?.type === 'month') {
                    localStorage.setItem("orderId", result);
                    await payment.requestBillingAuth({
                        method: "CARD", // 자동결제(빌링)는 카드만 지원합니다
                        successUrl: window.location.origin + "/billing/success", // 요청이 성공하면 리다이렉트되는 URL
                        failUrl: window.location.origin + "/billing/fail", // 요청이 실패하면 리다이렉트되는 URL
                        customerEmail: "customer123@gmail.com",
                        customerName: "김토스",
                    });
                } else {
                    try {
                        await widgets.requestPayment({
                            orderId: 'PHARMPICKORDER' + result,
                            orderName: pageData?.options[0]?.productName,
                            successUrl: window.location.origin + "/success",
                            failUrl: window.location.origin + "/fail",
                            customerEmail: "customer123@gmail.com",
                            customerName: "김토스",
                            customerMobilePhone: "01012341234",
                        });
                    } catch (error) {
                        // 에러 처리하기
                        console.error(error);
                    }
                }
            },
            err: (result) => {
                navigate(0)
                setBottomMsgData({
                    text : result.message||"",
                    chk : bottomMsgData.chk + 1
                });
            }
        })
    }

    function handleRegisterAddress() {
        if (!newDeliveryName || !newDeliveryPhone || !newDeliveryAddress || !newDeliveryAddressDetail || !newDeliveryPostcode) {
            setBottomMsgData({
                text : "배송지를 입력해주세요",
                chk : bottomMsgData.chk + 1
            });
            return false
        }

        let payload = {
            addressId: newDeliveryId,
            recipientName: newDeliveryName,
            recipientPhone: newDeliveryPhone,
            recipientAddress: newDeliveryAddress,
            recipientAddressDetail: newDeliveryAddressDetail,
            recipientAddressPostcode: newDeliveryPostcode,
            addressSave: newDeliveryDefault ? 1 : 0
        };

        fatchSet.FatchApi({
            type: "POST",
            formDataItem: JSON.stringify(payload),
            loginType:"login",
            url: deliveryModifyPopup ? `/address/modify` : `/address/register`,
            success: (result) => {
                setNewDeliveryId('')
                setNewDeliveryName('')
                setNewDeliveryPhone('')
                setNewDeliveryAddress('')
                setNewDeliveryAddressDetail('')
                setNewDeliveryPostcode('')

                if (deliveryRegisterPopup) {
                    setDeliveryRegisterPopup(false);
                }

                if (deliveryModifyPopup) {
                    setDeliveryModifyPopup(false);
                }

                setUpdate(update + 1)
            },
            err: (result) => {
                if(result.data || result.alert){
                    setBottomMsgData({
                        text : result.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function handleDeleteAddress() {
        fatchSet.FatchApi({
            type: "DELETE",
            loginType:"login",
            url: `/address/delete?addressId=${newDeliveryId}`,
            success: (result) => {
                setNewDeliveryId('')
                setNewDeliveryName('')
                setNewDeliveryPhone('')
                setNewDeliveryAddress('')
                setNewDeliveryAddressDetail('')
                setNewDeliveryPostcode('')

                setDeliveryModifyPopup(false);

                setUpdate(update + 1)
            },
            err: (result) => {
                if(result.data || result.alert){
                    setBottomMsgData({
                        text : result.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }
    
    return (
        <>
            {!order ? (<>
                <Header
                    addClass="pageMainBg nonBg"
                    headTitle={deliveryRegisterPopup ? "신규 배송지 등록" : (deliveryModifyPopup ? "배송지 정보 수정" : (deliveryPopup ? "배송지 변경" : pageData?.type === 'present' ? "선물 주문서 작성" : "주문서 작성")) }
                    func={() => {if (deliveryRegisterPopup) { setDeliveryRegisterPopup(false) } else if (deliveryModifyPopup) { setDeliveryModifyPopup(false) } else if (deliveryPopup) { setDeliveryPopup(false) } else { navigate(-1) }}}
                    centerTitle={true}
                    homeBtn={(deliveryRegisterPopup || deliveryModifyPopup) ? false : true}
                    pageBack={(deliveryRegisterPopup || deliveryModifyPopup) ? false : true}
                    closeBtn={(deliveryRegisterPopup || deliveryModifyPopup) ? true : false}
                    color="black"
                />
                <ContentsSection addClass={`headerContents ${deliveryPopup ? 'hide' : ''}`}>
                    <div className="orderDetailBox">
                        <OrderData type={pageData?.type} options={pageData?.options}/>
                    </div>
                    <div className="orderHr"/>
                    {pageData?.type === 'present' ? (
                        <div className="inputContentsBox">
                            <div className="inputTitle">
                                <div className="left">선물 수령자 정보</div>
                            </div>
                            <div className="inputItem">
                                <div className="title">받는 분 성함</div>
                                <div className="contents">
                                    <InputItemBox
                                        inputType="text"
                                        placeholder="이름을 입력해주세요."
                                        value={deliveryName}
                                        max={null}
                                        regexp={null}
                                        func={(value)=>{ setDeliveryName(value) }}
                                    />
                                </div>
                            </div>
                            <div className="subContents">연락처와 배송지 정보는 선물 받는 분이 직접 입력해요.</div>
                        </div>
                    ) : (
                        <div className="inputContentsBox">
                            <div className="inputTitle">
                                <div className="left">배송 정보</div>
                                {addressApi?.length <= 0 && <div className="right"><ChkBox label={''} checkedType={deliverySave} func={() => {setDeliverySave(!deliverySave)}}/> 배송 정보 저장하기</div>}
                            </div>
                            {addressApi?.length > 0 ? (<div className="deliveryBox">
                                <div className="detail">
                                    <div className="title">{deliveryName} ({deliveryPhone})</div>
                                    <div className="contents">{deliveryAddress} {deliveryAddressDetail}</div>
                                </div>
                                <button type="button" className={`btn_popup`} onClick={() => setDeliveryPopup(true)}>배송지 변경</button>
                            </div>) : (<>
                                <div className="inputItem">
                                    <div className="title">이름</div>
                                    <div className="contents">
                                        <InputItemBox
                                            inputType="text"
                                            placeholder="이름을 입력해주세요."
                                            value={deliveryName}
                                            max={null}
                                            regexp={null}
                                            func={(value)=>{ setDeliveryName(value) }}
                                        />
                                    </div>
                                </div>
                                <div className="inputItem">
                                    <div className="title">휴대폰 번호</div>
                                    <div className="contents">
                                        <InputItemBox
                                            inputType="text"
                                            placeholder="휴대폰 번호를 입력해주세요."
                                            value={PhoneSetting(deliveryPhone)}
                                            max={13}
                                            regexp={null}
                                            func={(value)=>{ setDeliveryPhone(value) }}
                                        />
                                    </div>
                                </div>
                                <div className="inputItem">
                                    <div className="title">배송지</div>
                                    <div className="contents">
                                        <InputItemBox
                                            inputType="text"
                                            placeholder="주소 검색"
                                            value={deliveryAddress}
                                            max={null}
                                            regexp={null}
                                            func={(value)=>{ }}
                                            clickInput={(value)=>{addrShOpen("order")}}
                                        />
                                    </div>
                                    <div className="contents">
                                        <InputItemBox
                                            inputType="text"
                                            placeholder="상세 주소 입력"
                                            value={deliveryAddressDetail}
                                            max={null}
                                            regexp={null}
                                            func={(value)=>{ setDeliveryAddressDetail(value) }}
                                        />
                                    </div>
                                </div>
                            </>)}
                            {pageData?.type === 'month' && (
                                <div className="subContents">정기배송 기간동안 배송지를 변경해야 한다면 <br/>
                                    고객센터로 문의주시면 변경해드려요.</div>
                            )}
                        </div>
                    )}
                    {pageData?.type !== 'month' && (<>
                        <div className="orderHr"/>
                        <div className="inputContentsBox">
                            <div className="inputTitle">포인트 할인</div>
                            <div className="inputItem">
                                <div className="title">사용할 포인트 입력</div>
                                <div className="contents">
                                    <InputItemBox
                                        value={point}
                                        max={null}
                                        unit='P'
                                        func={(value)=>{
                                            if (value >= pointApi?.total_point ) {
                                                if (pageData?.options?.reduce((acc, item) => acc + item.total_price, 0) + deliveryApi?.deliveryPrice <= value) {
                                                    setPoint(pageData?.options?.reduce((acc, item) => acc + item.total_price, 0) + deliveryApi?.deliveryPrice - 1000)
                                                } else {
                                                    setPoint(String(pointApi?.total_point))
                                                }
                                            } else if (pageData?.options?.reduce((acc, item) => acc + item.total_price, 0) + deliveryApi?.deliveryPrice <= value) {
                                                setPoint(pageData?.options?.reduce((acc, item) => acc + item.total_price, 0) + deliveryApi?.deliveryPrice - 1000)
                                            } else {
                                                setPoint(value.replace(/[^0-9]/gi,""));
                                            }
                                        }}
                                    />
                                    <div className="summary">
                                        <div>사용 가능한 포인트</div>
                                        <div>{pointApi?.total_point ? comFormat(pointApi?.total_point) : 0} P</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)}
                    <div className="orderHr"/>
                    <div className="orderDetailBox pb-20">
                        <div className="orderInfo">
                            <div className="orderTitle">구매 정보</div>
                            <div className="orderContentsBox">
                                {pageData?.type === 'month' && (
                                    <div className="orderContents">
                                        <div className="title">배송받는 주기</div>
                                        <div className="contents">매 월 {moment(pageData?.selectDate).format("D")}일</div>
                                    </div>
                                )}
                                <div className="orderContents">
                                    <div className="title">상품 금액</div>
                                    <div className="contents">{comFormat(pageData?.options?.reduce((acc, item) => acc + item.total_price, 0))}원</div>
                                </div>
                                <div className="orderContents">
                                    <div className="title">배송비</div>
                                    <div className="contents">
                                        {deliveryApi?.deliveryPrice === 0 ? '무료' : comFormat(deliveryApi?.deliveryPrice) + '원'}
                                        {deliveryApi?.type === 'countryside' && (<span><br/>도서산간 배송비 적용</span>)}
                                    </div>
                                </div>
                                {pageData?.type !== 'month' && (
                                    <div className="orderContents">
                                        <div className="title">포인트 할인</div>
                                        <div className="contents">- {comFormat(point)}원</div>
                                    </div>
                                )}
                            </div>
                            <div className="orderTotal">
                                <div className="title">총 결제금액</div>
                                <div className="contents">{comFormat(pageData?.options?.reduce((acc, item) => acc + item.total_price, 0) - point + deliveryApi?.deliveryPrice)}원</div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className={`btn_popup full`}
                            onClick={() => {
                                if (pageData?.type === 'month') {
                                    handleOrder()
                                } else {
                                    if (pageData?.type === 'present' && deliveryName === '') {
                                        setBottomMsgData({
                                            text : "받는 분 성함을 입력해주세요",
                                            chk : bottomMsgData.chk + 1
                                        });
                                        return false
                                    }

                                    pageData?.options?.map((option) => {
                                        fatchSet.FatchApi({
                                            type: "GET",
                                            loginType:"login",
                                            url: `/product/shop/amount/check?registrationNumber=${option?.productId}&optionId=${option?.id ? option?.id : ''}`,
                                            success: async (result) => {
                                                if (Number(result) < option?.qty) {
                                                    setBottomMsgData({
                                                        text : option?.productName + "상품의 재고가 부족합니다.",
                                                        chk : bottomMsgData.chk + 1
                                                    });
                                                    setOrder(false)

                                                    return false
                                                }
                                            },
                                            err: (result) => {
                                                setOrder(false);
                                                setBottomMsgData({
                                                    text : result.message||"",
                                                    chk : bottomMsgData.chk + 1
                                                });

                                                return false
                                            }
                                        })
                                    })
                                    setOrder(true);
                                    renderPaymentWidgets()
                                }
                            }}
                        >
                            {comFormat(pageData?.options?.reduce((acc, item) => acc + item.total_price, 0) - point + deliveryApi?.deliveryPrice)}원 구매하기
                        </button>
                    </div>
                </ContentsSection>
                <ContentsSection addClass={`headerContents ${deliveryPopup && !deliveryRegisterPopup && !deliveryModifyPopup ? '' : 'hide'}`}>
                    <div className="inputContentsBox">
                        <div className="deliveryItemBox">
                            {addressApi?.length > 0 && addressApi?.map((address) => (
                                <div className="deliveryBox">
                                    <div  onClick={() => {
                                        setDeliveryModifyPopup(true)
                                        setNewDeliveryId(address.id)
                                        setNewDeliveryName(address.name)
                                        setNewDeliveryPhone(address.phone)
                                        setNewDeliveryAddress(address.address)
                                        setNewDeliveryAddressDetail(address.addressDetail)
                                        setNewDeliveryPostcode(address.postcode)
                                        setNewDeliveryDefault(address.isDefault)
                                    }}>
                                        {address?.isDefault === 1 && (<div className="label">기본 배송지</div>)}
                                        <div className="detail">
                                            <div className="title">{address?.name} ({address?.phone})</div>
                                            <div className="contents">{address?.address} {address?.addressDetail}</div>
                                        </div>
                                    </div>
                                    {deliveryId === address?.id ? (<button type="button" className={`btn_popup switch`} onClick={() => setDeliveryPopup(false)}>현재 선택된 배송지</button>) :
                                        (<button type="button" className={`btn_popup`}
                                                 onClick={() => {
                                                     setDeliveryId(address?.id);
                                                     setDeliveryName(address?.name)
                                                     setDeliveryPhone(address?.phone)
                                                     setDeliveryAddress(address?.address)
                                                     setDeliveryAddressDetail(address?.addressDetail)
                                                     setDeliveryPostcode(address?.postcode)
                                                     setDeliveryPopup(false)
                                                 }}>이 배송지로 변경</button>)}
                                </div>
                            ))}
                        </div>
                    </div>
                    <BtnBox boxType="fixed" addClass={`indexUp fixButtonBox`}>
                        <button
                            className="fixButton full"
                            onClick={() => {
                                setNewDeliveryId('')
                                setNewDeliveryName('')
                                setNewDeliveryPhone('')
                                setNewDeliveryAddress('')
                                setNewDeliveryAddressDetail('')
                                setNewDeliveryPostcode('')

                                setDeliveryRegisterPopup(true)
                            }}
                        >
                            신규 배송지 등록
                        </button>
                    </BtnBox>
                </ContentsSection>
                <ContentsSection addClass={`headerContents ${deliveryRegisterPopup || deliveryModifyPopup ? '' : 'hide'}`}>
                    <div className="inputContentsBox">
                        <div className="inputTitle">
                            <div className="left">배송 정보</div>
                            <div className="right"><ChkBox label={''} checkedType={newDeliveryDefault} func={() => { setNewDeliveryDefault(!newDeliveryDefault) }}/> 기본 배송지로 설정</div>
                        </div>
                        <div className="inputItem">
                            <div className="title">이름</div>
                            <div className="contents">
                                <InputItemBox
                                    inputType="text"
                                    placeholder="이름을 입력해주세요."
                                    value={newDeliveryName}
                                    max={null}
                                    regexp={null}
                                    func={(value) => { setNewDeliveryName(value) }}
                                />
                            </div>
                        </div>
                        <div className="inputItem">
                            <div className="title">휴대폰 번호</div>
                            <div className="contents">
                                <InputItemBox
                                    inputType="text"
                                    placeholder="휴대폰 번호를 입력해주세요."
                                    value={PhoneSetting(newDeliveryPhone)}
                                    max={null}
                                    regexp={null}
                                    func={(value) => { setNewDeliveryPhone(value) }}
                                />
                            </div>
                        </div>
                        <div className="inputItem">
                            <div className="title">배송지</div>
                            <div className="contents">
                                <InputItemBox
                                    inputType="text"
                                    placeholder="주소 검색"
                                    value={newDeliveryAddress}
                                    max={null}
                                    regexp={null}
                                    clickInput={(value)=>{addrShOpen("popup")}}
                                />
                            </div>
                            <div className="contents">
                                <InputItemBox
                                    inputType="text"
                                    placeholder="상세 주소 입력"
                                    value={newDeliveryAddressDetail}
                                    max={null}
                                    regexp={null}
                                    func={(value) => { setNewDeliveryAddressDetail(value) }}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        deliveryModifyPopup
                            ?
                            <BtnBox boxType="fixed" addClass={`indexUp fixButtonBox`}>
                                <button className="fixButton" onClick={() => { handleDeleteAddress() }}>{"배송지 삭제"}</button>
                                <button className="fixButton full" onClick={() => { handleRegisterAddress() }}>{"배송지 수정"}</button>
                            </BtnBox>
                            :
                            <BtnBox boxType="fixed" addClass={`indexUp fixButtonBox`}>
                                <button className="fixButton full" onClick={() => { handleRegisterAddress() }}>{"신규 배송지 등록"}</button>
                            </BtnBox>
                    }
                </ContentsSection>
                <BottomErrMsg
                    text={bottomMsgData.text ? bottomMsgData.text : ""}
                    chk={bottomMsgData.chk}
                />
                <Popup data={popupData}/>
            </>) : (<div className="tossBox">
                <div id="payment-method"/>
                <div id="agreement"/>
                <button className="tossButton" onClick={() => handleOrder()}>결제하기</button>
            </div>)}
        </>
    );
};

export default Order;