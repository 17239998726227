export function FatchApi(fatchData){
    let headersData = (fatchData.loginType == "login" || fatchData.loginType == "sessionLogin") && fatchData.type == "POST" && fatchData?.contentType === "none" ? {
        method: `${fatchData.type}`,
        headers: {
            'Authorization': 'Bearer ' + `${fatchData.loginType == "login" ? localStorage.getItem("token") : sessionStorage.getItem("token")}`,
            'Accept' : 'application/json',
        },
        body: fatchData.formDataItem
    } : (fatchData.loginType == "login" || fatchData.loginType == "sessionLogin") && fatchData.type == "POST" ? {
        method: `${fatchData.type}`,
        headers: {
            'Authorization': 'Bearer ' + `${fatchData.loginType == "login" ? localStorage.getItem("token") : sessionStorage.getItem("token")}`,
            'Accept' : 'application/json',
            'Content-Type': `${fatchData?.contentType ? fatchData?.contentType : 'application/json'}`
        },
        body: fatchData.formDataItem
    } : (fatchData.type == "POST" || fatchData.type == "PUT") ? {
        method: `${fatchData.type}`,
        headers: {
            'Accept' : 'application/json',
            'Content-Type': `${fatchData?.contentType ? fatchData?.contentType : 'application/json'}`
        },
        body: fatchData.formDataItem
    } : (fatchData.loginType == "login" || fatchData.loginType == "sessionLogin") && fatchData.type == "DELETE" ? {
        method: `${fatchData.type}`,
        headers: {
            'Authorization': 'Bearer ' + `${fatchData.loginType == "login" ? localStorage.getItem("token") : sessionStorage.getItem("token")}`,
            'Accept' : 'application/json',
            'Content-Type': `${fatchData?.contentType ? fatchData?.contentType : 'application/json'}`
        },
        body: fatchData.formDataItem
    } : fatchData.type == "DELETE" ? {
        method: `${fatchData.type}`,
        headers: {
            'Accept' : 'application/json',
            'Content-Type': `${fatchData?.contentType ? fatchData?.contentType : 'application/json'}`
        },
        body: fatchData.formDataItem
    } : (fatchData.loginType == "login" || fatchData.loginType == "sessionLogin") ? {
        method: `${fatchData.type}`,
        headers: {
            'Authorization': 'Bearer ' + `${fatchData.loginType == "login" ? localStorage.getItem("token") : sessionStorage.getItem("token")}`,
            'Accept' : 'application/json',
            'Content-Type': `${fatchData?.contentType ? fatchData?.contentType : 'application/json'}`
        }
    } : {
        method: `${fatchData.type}`,
        headers: {
            'Accept' : 'application/json',
            'Content-Type': `${fatchData?.contentType ? fatchData?.contentType : 'application/json'}`
        }
    }

    fetch(`${fatchData?.fullurl ? '' : process.env.REACT_APP_API_URL}${fatchData?.fullurl ? fatchData?.fullurl : fatchData.url}`, headersData).then(function(response) {
        if(process.env.REACT_APP_TYPE == "live"){
            if(response?.status !== 200){
                if (response?.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refresh_token");
                    window.location.href = `/login`;
                } else {
                    //window.location.href = `/pageErr/${response?.status}`;
                }
            }
        }
        return response.text();
    }).then(function(data) {
        if (data && JSON.parse(data) && JSON.parse(data)?.status && JSON.parse(data)?.status !== 200) {
            fatchData.err(JSON.parse(data));
        } else {
            fatchData.success(data);
        }
    });

    return 
}