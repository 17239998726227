import React, {useEffect, useCallback, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {BottomErrMsg} from "component/basic/popup";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import * as fatchSet from "../../api/api";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import {CustomSelect, InputItemBox} from "../../component/basic/formItems";
import moment from "moment/moment";

const Join = (props) => {
    const box = useRef(null);
    const navigate = useNavigate();

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [submitChk,setSubmitChk] =  useState(true);
    const [bottomOpen,setBottomOpen] = useState(false);

    const [btnChk,setBtnChk] =  useState(true);

    const [email, setEmail] =  useState('');
    const [code, setCode] =  useState('');

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("10:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);

    const closeClick = useCallback(
        e => {
            let inside = box.current ? box.current.contains(e.target) : true;
            if (!inside){
                setBottomOpen(false);window.removeEventListener("click",closeClick);
            }
        }, [bottomOpen]
    );

    useEffect(() => {
        timerStart()
    }, []);

    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("9:59");
        setCertifyErr("");

        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }

        timerChk(9.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("인증시간이 초과되었습니다.");
                    setCetifiedComChk(true);
                    setCetified("");

                    setBtnChk(false);
                }
            }, 1000);
        }
    }

    function handlePhoneLogin(){
        if (code === cetified) {
            let payload = {
                "ci": localStorage.getItem("ci"),
                "phone": localStorage.getItem("phone"),
                "gender": localStorage.getItem("gender"),
                "birthday": moment(localStorage.getItem("birthday")).format("YYYY-MM-DD"),
                "way": "phone",
                "di": "",
                "email": email
            };

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: JSON.stringify(payload),
                loginType: "login",
                url: `/auth/phone-login`,
                success: (data) => {
                    var result = JSON.parse(data);
                    if (result?.accessToken) {
                        localStorage.setItem("token",result.accessToken);
                        localStorage.setItem("refresh_token",result.refreshToken);

                        navigate('/join/complete')
                    }
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setBottomMsgData({
                            text : data.alert||"",
                            chk : bottomMsgData.chk + 1
                        });
                    }
                }
            })
        } else {
            setBottomMsgData({
                text : "인증번호가 일치하지 않습니다",
                chk : bottomMsgData.chk + 1
            });
        }
    }

    function handleCetified() {
        let payload = {
            "email": email
        };

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: JSON.stringify(payload),
            loginType: "login",
            url: `/mail-send`,
            success: (data) => {
                setCetifiedChk(true)
                setCode(data)
            },
            err: (data) => {
                setSubmitChk(true);
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                addClass="pageMainBg nonBg"
                func={() => {navigate(-1)}}
                headTitle={"회원가입"}
                centerTitle={true}
                pageBack={true}
                color="black"
            />
            <ContentsSection addClass="headerContents">
                <div className="inputContentsBox pb-20">
                    <div className="inputItem">
                        <div className="title">이메일 주소</div>
                        <div className="contents">
                            <InputItemBox
                                inputType="text"
                                placeholder="이메일 주소를 입력해주세요."
                                value={email}
                                max={null}
                                regexp={null}
                                func={(value) => {
                                    setEmail(value)
                                }}
                            />
                        </div>
                    </div>
                    {cetifiedChk && (
                        <div className="inputItem">
                            <div className="title">인증번호 입력</div>
                            <div className="contents flex">
                                <InputItemBox
                                    boxStyle={{width: "70%"}}
                                    inputType={null}
                                    addClass="cetifiedInput"
                                    inputName="인증 번호"
                                    placeholder="6자리 입력"
                                    value={cetified}
                                    max={6}
                                    readOnly={cetifiedComChk}
                                    cetified={timerCount}
                                    regexp={"number"}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    func={(value) => {
                                        setCetified(value)
                                    }}
                                    errMsg={certifyErr}
                                />
                                <button className="fixButton" style={{margin: "8px 0 0 10px", height: 50}}
                                        onClick={() => handleCetified()}>다시 받기
                                </button>
                            </div>
                        </div>
                    )}
                    {!cetifiedChk && (
                        <>
                            <button className="fixButton full" onClick={() => handleCetified()}>인증번호 받기</button>
                            <div className="subText">· 서비스 이용 약관 변경, 개인정보 이용 내역 고지 등의 서비스 안내를 위해 사용됩니다.</div>
                        </>
                    )}
                </div>
                {cetifiedChk && (
                    <div className="pageBtn_box indexUp fixButtonBox">
                        <button className="fixButton full" onClick={() => handlePhoneLogin()}>확인</button>
                    </div>
                )}
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Join;