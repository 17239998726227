import Private from "pages/private/Private";
import PublicOnly from "pages/private/PublicOnly";
import React, { useEffect, useState } from "react";
import * as fatchSet from "../api/api";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "component/basic/scrollTopSet";
import { BottomErrMsg, LoadingBox, Popup } from "component/basic/popup";
import { ErrorBoundary } from "react-error-boundary";
import { routes } from "./routes";
import { PageSizing } from "component/app/items";
import PcView from "./PcView";

const Router = () => {
    const [token,setToken] = useState(localStorage.getItem("token") ? true : false);
    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    window.saveToken = (token, refreshToken) => {
        if (token !== localStorage.getItem("token")) {
            localStorage.setItem("token", token);
            localStorage.setItem("refresh_token", refreshToken);
            window.location.reload();
        }
    }

    window.logIn = function(){
        setToken(true);
    }
    window.logOut = function(){
        let formData = new FormData();

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/logout`,
            loginType: "login",
            success: (data) => {
                localStorage.removeItem("token");
                localStorage.removeItem("refresh_token");

                localStorage.removeItem("companyId")

                setToken(false);
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    window.errPopupOpen = (data) => {
        if(data.data || data.alert){
            setPopupData({
                addClass:null,
                title:data.alert||"",
                text:data.data||"",
                closeType:true,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{},
                btn0:"확인",
                btn0Type:"",
                btn1:"",
                btn1Type:"",
                btnFunc1:()=>{}
            });
        }
    }

    window.errMsg = (msg) => {
        if(msg){
            setBottomMsgData({
                text : msg,
                chk : bottomMsgData.chk + 1
            });
        }
    }

    window.loadingBox = (type) => {
        setLoadingBoxOpen(type);
    }

    window.sns_login = async (type, token) => {
        const formData = new FormData();
        formData.append("code", token);
        formData.append("is_token", 1);

        fetch(`https://api/${type}/login`, {
            method: "POST", body: formData
        }).then(function (response) {
            return response.json();
        }).then((data) => {
            if(data.success === true){
                localStorage.setItem("token",data.data.access_token);
                localStorage.setItem("refresh_token",data.data.refresh_token);

                window.logIn();

                if(data.data.is_check_password === 1){
                    if(data.data.company_count === 1){
                        localStorage.setItem("companyId",data.data.company_id);
                        window.location.href = "/workHistory";
                    }else{
                        window.location.href = `/member/company`;
                    }
                }else{
                    window.location.href = `/member/pwSetting`;
                }
            } else {
                window.alert(data.alert);
            }
        }).catch((e) => {
            window.alert('일시적인 오류가 발생했습니다. 잠시 후 다시 시도해주세요');
        });
    };

    useEffect(()=>{
        
    },[]);

    //백화 시 노출
    const ErrorFallback = (err) => {
        return (
            <div className="pageSizing">
                <div className="pageErrBox">
                    <h1 className="pageErrTitle">서비스 이용에 불편을 드려 죄송합니다.</h1>
                    <p className="pageErrText">서비스 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요</p>
                </div>
                <div className="pageBtn_box">
                    <button type="button" className="pageBtn" onClick={() => window.location.href = "/"}>처음으로</button>
                </div>
            </div>
        );
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
                <ScrollToTop/>
                <PageSizing>
                    <PcView>
                        <Routes>
                            {routes.map(
                                ({ exact, path, component, type }) => (
                                    <Route
                                        key={`public-route-${path}`}
                                        exact={exact}
                                        path={path}
                                        element={type == "login" ?
                                            token ? component : <Private/>
                                            : type == "notLogin" ?
                                                token ? <PublicOnly/> : component
                                                : component}
                                    />
                                )
                            )}

                            {/*404*/}
                            <Route
                                key={`public-route-/*`}
                                exact={true}
                                path="/*"
                                element={<PublicOnly/>}
                            />
                        </Routes>
                    </PcView>
                </PageSizing>
                <Popup
                    data={popupData}
                />
                <BottomErrMsg
                    text={bottomMsgData.text ? bottomMsgData.text : ""}
                    chk={bottomMsgData.chk}
                />
                <LoadingBox openType={loadingBoxOpen}/>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default Router;