import { useEffect, useState } from "react";

export default function useGet(fatchData){
    const [data,setData] = useState({});

    let apiCheck = false;

    const headersData = (fatchData.loginType == "login" && localStorage.getItem("token")) ? {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Accept' : 'application/json'
        },
    } : fatchData.loginType == "sessionLogin" ? {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
            'Accept' : 'application/json'
        },
    } : {
        method: "GET",
        headers: {
            'Accept' : 'application/json'
        },
    }

    useEffect(() => {
        if (!apiCheck) {
            apiCheck = true
            if((fatchData.chk || fatchData.chk == undefined || fatchData.chk == null) && fatchData.url){
                fetch(`${process.env.REACT_APP_API_URL}${fatchData.url}`, headersData)
                .then(function(response) {
                    if (response?.status === 403) {
                        if (localStorage.getItem("token")) {
                            localStorage.removeItem("token");
                            localStorage.removeItem("refresh_token");
                            window.errMsg("접근 권한이 없습니다")
                            window.location.href = `/login`;
                        }
                    } else if(response?.status !== 200){
                        //window.location.href = `/pageErr/${response?.status}`;
                    }
                    return response.json();
                }).then(function(fetchRd) {
                    apiCheck = false
                    if (fetchRd.success == true) {
                        setData(fetchRd);
                    }else{
                        setData(fetchRd);
                    }
                });
            }
        }
    },[fatchData.url, fatchData.mutate]);

    return data;
}