import Main from "pages/market/Main";
import ProductDetail from "pages/market/Detail";
import Login from "pages/user/Login";
import LoginPhone from "pages/user/Phone";
import LoginNew from "pages/user/New";
import LoginLink from "pages/user/Link";
import Join from "pages/user/Join";
import JoinComplete from "pages/user/JoinComplete";
import FindAccount from "../pages/user/FindAccount";
import Cart from "pages/cart/Index";
import Order from "pages/order/Order";
import OrderComplete from "pages/order/Complete";
import Present from "pages/order/Present";
import PresentComplete from "pages/order/PresentComplete";
import PresentFail from "pages/order/PresentFail";
import Point from "pages/point/Index";
import Review from "pages/review/Index";
import ReviewRegister from "pages/review/Register";
import Refund from "pages/mypage/order/Refund";
import Exchange from "../pages/mypage/order/Exchange";
import Mypage from "pages/mypage/Index";
import MypageOrder from "pages/mypage/order/Index";
import MypageOrderDetail from "pages/mypage/order/Detail";
import Success from "pages/order/Success";
import Fail from "pages/order/Fail";
import BillingSuccess from "pages/order/BillingSuccess";
import BillingFail from "pages/order/BillingFail";
import PublicOnly from "pages/private/PublicOnly";
import React from "react";
import { Navigate } from "react-router-dom";

//type
//login : 로그인 한정 페이지
// notLogin : 비로그인 한정 페이지
// null : 로그인,비로그인 무관 페이지

export const routes = [
    {
        exact: true,
        path: "/pageErr",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/pageErr/:err",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/",
        component: <Navigate to="/market" />,
        type : null
    },
    {
        exact: true,
        path: "/login",
        component: <Login/>,
        type : null
    },
    {
        exact: true,
        path: "/login/phone",
        component: <LoginPhone/>,
        type : null
    },
    {
        exact: true,
        path: "/login/new",
        component: <LoginNew/>,
        type : null
    },
    {
        exact: true,
        path: "/login/link",
        component: <LoginLink/>,
        type : null
    },
    {
        exact: true,
        path: "/join",
        component: <Join/>,
        type : null
    },
    {
        exact: true,
        path: "/join/Complete",
        component: <JoinComplete/>,
        type : null
    },
    {
        exact: true,
        path: "/find/account",
        component: <FindAccount/>,
        type : null
    },
    {
        exact: true,
        path: "/market",
        component: <Main/>,
        type : null
    },
    {
        exact: true,
        path: "/product/detail/:id",
        component: <ProductDetail/>,
        type : null
    },
    {
        exact: true,
        path: "/order",
        component: <Order/>,
        type : "login"
    },
    {
        exact: true,
        path: "/order/complete/:id",
        component: <OrderComplete/>,
        type : null
    },
    {
        exact: true,
        path: "/present/:id",
        component: <Present/>,
        type : null
    },
    {
        exact: true,
        path: "/present/complete",
        component: <PresentComplete/>,
        type : null
    },
    {
        exact: true,
        path: "/present/fail",
        component: <PresentFail/>,
        type : null
    },
    {
        exact: true,
        path: "/cart",
        component: <Cart/>,
        type : null
    },
    {
        exact: true,
        path: "/point",
        component: <Point/>,
        type : null
    },
    {
        exact: true,
        path: "/review/all/:id",
        component: <Review/>,
        type : null
    },
    {
        exact: true,
        path: "/review/register/:id",
        component: <ReviewRegister/>,
        type : null
    },
    {
        exact: true,
        path: "/mypage",
        component: <Mypage/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/order",
        component: <MypageOrder/>,
        type : "login"
    },
    {
        exact: true,
        path: "/mypage/order/detail/:id",
        component: <MypageOrderDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/refund/:id",
        component: <Refund/>,
        type : "login"
    },
    {
        exact: true,
        path: "/exchange/:id",
        component: <Exchange/>,
        type : "login"
    },
    {
        exact: true,
        path: "/success",
        component: <Success/>,
        type : null
    },
    {
        exact: true,
        path: "/fail",
        component: <Fail/>,
        type : null
    },
    {
        exact: true,
        path: "/billing/success",
        component: <BillingSuccess/>,
        type : null
    },
    {
        exact: true,
        path: "/billing/fail",
        component: <BillingFail/>,
        type : null
    },
];