import React from "react";

const PcView = ({ children }) => {

  return (
      <div className="pcViewWrap">
        <div className="pcViewBox">
          <div className="pcViewItem">
            <img src="/assets/images/icon/pcViewIcon.svg"/>
            <div className="pcViewDownload">
              <button type="button"/>
              <button type="button"/>
            </div>
          </div>
          <div className="pcViewContents">{children}</div>
        </div>
      </div>
  );
};

export default PcView;